import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-token-expired-dialog',
  template: `
    <p>Votre session est expirée, veuillez vous reconnecter</p>
    <button mat-button (click)="closeDialog()">Accepter</button>
  `,
})
export class TokenExpiredDialogComponent {
  constructor(private dialogRef: MatDialogRef<TokenExpiredDialogComponent>) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
