import { Injectable } from '@angular/core';
import { AbstractBreadcrumbService, BreadcrumbItem, BreadcrumbState } from 'sirc-lib';


@Injectable({
  providedIn: 'root'
})
export class BreadcrumbSharedService extends AbstractBreadcrumbService {

  public initBreadcrumb(): void {
    const home = new BreadcrumbItem('Accueil', `/accueil`);
    const follow = new BreadcrumbItem(`Suivre une demande d'homologation`, '/demandes/suivi-homologation');
    const simulatorDeadlines
      = new BreadcrumbItem(`Simulation des délais de rétractation et d'homologation`, '/simulateur/calendrier');
    const simulatorIndemnity = new BreadcrumbItem(`Simulation de l'indemnité légale`, '/simulateur/indemnite');
    const demande = new BreadcrumbItem('Demandes', '');

    this.breadcrumbItems = new Map([
      [BreadcrumbState.LOGOUT, []],
      [BreadcrumbState.HOME, [home]],
      [BreadcrumbState.FOLLOW, [home, follow]],
      [BreadcrumbState.SIMULATOR_DEADLINES, [home, simulatorDeadlines]],
      [BreadcrumbState.SIMULATOR_INDEMNITY, [home, simulatorIndemnity]],
      [BreadcrumbState.DEMANDE, [home, demande]],
      [BreadcrumbState.HELP_LIBRARY, [home]],
    ]);
  }
}
