import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PasswordDefinitionResolver } from './core/resolvers/password-definition.resolver';
import { ParametreResolver } from './resolvers/parametre.resolver';
import { DemandeDefinitionPasswordComponent } from './web/demandes/demande-definition-password/demande-definition-password.component';
import { HomeComponent } from './web/home/home.component';
import { SimulateurDelaisComponent } from './web/simulateur-delais/simulateur-delais.component';
import { SimulateurIndemniteComponent } from './web/simulateur-indemnite/simulateur-indemnite.component';
import { SuiviHomologationComponent } from './web/suivi-homologation/suivi-homologation.component';




const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./web/authentification/authentification.module').then(m => m.AuthentificationModule),
  },
  {
    path: '',
    redirectTo: '/accueil',
    pathMatch: 'full',
  },
  {
    path: 'accueil',
    component: HomeComponent,
    resolve: {
      parametre: ParametreResolver
    }
  },
  {
    path: 'mot-de-passe/definition/:uuid',
    component: DemandeDefinitionPasswordComponent,
    resolve: {
      exist: PasswordDefinitionResolver,
    }
  },
  {
    path: 'mot-de-passe/definition/:uuid/:token',
    component: DemandeDefinitionPasswordComponent,
    resolve: {
      exist: PasswordDefinitionResolver,
    },
  },
  {
    path: 'demandes',
    loadChildren: () => import('./web/demandes/demande-management/demande-management.module')
      .then((mod) => mod.DemandeManagementModule)
  },
  {
    path: 'demandes/suivi-homologation',
    component: SuiviHomologationComponent
  },
  {
    path: 'demandes/suivi-homologation/:demande',
    component: SuiviHomologationComponent
  },
  {
    path: 'simulateur/calendrier',
    component: SimulateurDelaisComponent
  },
  {
    path: 'simulateur/indemnite',
    component: SimulateurIndemniteComponent
  },
  {
    path: 'espace-documentaire',
    loadChildren: () => import('./web/espace-documentaire/espace-documentaire.module')
      .then((mod) => mod.EspaceDocumentaireModule)
  },

  // Default
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    // Change option for permit refresh page with same URL navigation
    // (combine with resolver and 'runGuardsAndResolvers' to route definition)
    onSameUrlNavigation: 'reload',
    // Configuration anchor navigation
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 100],
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
