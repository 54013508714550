export class NumberToWords {
  /*
    '***********
    ' Devise=0 aucune
    ' =1 Euro €
    '***********
    ' Conversion limitée à 999 999 999 999 999 ou 9 999 999 999 999,99
    ' si le nombre contient plus de 2 décimales, il est arrondit à 2 décimales
    */
  public static convNumberLetter(nombre: number, bCheckFloat = true): string {
    const strNombre = nombre + '';
    let TabNombre = new Array();
    let strLetter = '';
    if (isNaN(nombre)) { return '-'; }
    TabNombre = strNombre.split('.');
    if (TabNombre.length > 2 || TabNombre.length <= 0 || strNombre.slice(-1) === '.') {
      return '';
    }

    for (let i = 0; i < TabNombre.length; i++) {
      if (i === 0) {
        strLetter = strLetter + this.convNumberLetterWithDevise(parseFloat(TabNombre[i]), 1);
      } else {
        // Used to prevent .01 to .09 cents to be multiplied by 10
        let firtDecimalDigit = NumberToWords.getFirstDigit(TabNombre[1])

        if (parseInt('' + TabNombre[i], 10 ) < 10 && firtDecimalDigit != 0){
          TabNombre[i] = parseInt('' + TabNombre[i], 10) * 10;
        }
        if (parseFloat(TabNombre[i]) > 0) {
          strLetter = strLetter + 'et ';
        }
        strLetter = strLetter + this.convNumberLetterWithDevise(parseFloat(TabNombre[i]), 0);
        if (parseFloat(TabNombre[i]) > 1) {
          strLetter = strLetter + 'centimes';
        } else if (parseFloat(TabNombre[i]) > 0) {
          strLetter = strLetter + 'centime';
        }
      }
    }
    return strLetter.substr(0, 1).toUpperCase() + strLetter.substr(1, strLetter.length).toLowerCase();
  }

  public static getFirstDigit(num) {
    // 1: get first digit using regex pattern
    const matches = String(num).match(/\d/);
    // 2: convert matched item to integer
    const digit = Number(matches[0]);
    // 3: add sign back as needed
    return (num < 0) ? -digit : digit;
  }

  private static convNumberLetterWithDevise(Nombre: number, Devise): string {
    let dblEnt;
    let byDec;
    let bNegatif;
    let strDev = '';
    let strCentimes = '';

    if (Nombre < 0) {
      bNegatif = true;
      Nombre = Math.abs(Nombre);
    }
    dblEnt = parseInt(Nombre.toString(), 10);
    byDec = parseInt(((Nombre - dblEnt) * 100).toString(), 10);
    if (byDec === 0) {
      if (dblEnt > 999999999999999) {
        return 'Valeur trop élevée';
      }
    } else {
      if (dblEnt > 9999999999999.99) {
        return 'Valeur trop élevée';
      }
    }
    switch (Devise) {
      case 0:
        if (byDec > 0) { strDev = ' virgule'; } break;
      case 1:
        strDev = ' Euro';
        if (byDec > 0) { strCentimes = strCentimes + ' Cents'; } break;
    }
    if (dblEnt > 1 && Devise !== 0) { strDev = strDev + 's'; }
    return this.convNumEnt(parseFloat(dblEnt)) + strDev + ' ' + this.convNumDizaine(byDec) + strCentimes;
  }

  private static convNumEnt(Nombre: number): string {
    let iTmp;
    let dblReste;
    let StrTmp = '';
    let NumEnt;

    iTmp = Nombre - (parseInt((Nombre / 1000).toString(), 10) * 1000);
    NumEnt = this.convNumCent(parseInt(iTmp, 10));
    dblReste = parseInt((Nombre / 1000).toString(), 10);
    iTmp = dblReste - (parseInt((dblReste / 1000).toString(), 10) * 1000);
    StrTmp = this.convNumCent(parseInt(iTmp, 10));
    switch (iTmp) {
      case 0:
        break;
      case 1:
        StrTmp = 'mille ';
        break;
      default:
        StrTmp = StrTmp + ' mille ';
    }
    NumEnt = StrTmp + NumEnt;
    dblReste = parseInt((dblReste / 1000).toString(), 10);
    iTmp = dblReste - (parseInt((dblReste / 1000).toString(), 10) * 1000);
    StrTmp = this.convNumCent(parseInt(iTmp, 10));
    switch (iTmp) {
      case 0:
        break;
      case 1:
        StrTmp = StrTmp + ' million ';
        break;
      default:
        StrTmp = StrTmp + ' millions ';
    }
    NumEnt = StrTmp + NumEnt;
    dblReste = parseInt((dblReste / 1000).toString(), 10);
    iTmp = dblReste - (parseInt((dblReste / 1000).toString(), 10) * 1000);
    StrTmp = this.convNumCent(parseInt(iTmp, 10));
    switch (iTmp) {
      case 0:
        break;
      case 1:
        StrTmp = StrTmp + ' milliard ';
        break;
      default:
        StrTmp = StrTmp + ' milliards ';
    }
    NumEnt = StrTmp + NumEnt;
    dblReste = parseInt((dblReste / 1000).toString(), 10);
    iTmp = dblReste - (parseInt((dblReste / 1000).toString(), 10) * 1000);
    StrTmp = this.convNumCent(parseInt(iTmp, 10));
    switch (iTmp) {
      case 0:
        break;
      case 1:
        StrTmp = StrTmp + ' billion ';
        break;
      default:
        StrTmp = StrTmp + ' billions ';
    }
    NumEnt = StrTmp + NumEnt;
    return NumEnt;
  }

  private static convNumDizaine(Nombre): string {
    let TabUnit;
    let TabDiz;
    let byUnit;
    let byDiz;
    let strLiaison = '';

    TabUnit = Array('', 'un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept',
      'huit', 'neuf', 'dix', 'onze', 'douze', 'treize', 'quatorze',
      'quinze', 'seize', 'dix-sept', 'dix-huit', 'dix-neuf');
    TabDiz = Array('', '', 'vingt', 'trente', 'quarante', 'cinquante',
      'soixante', 'soixante', 'quatre-vingt', 'quatre-vingt');
    byDiz = parseInt((Nombre / 10).toString(), 10);
    byUnit = Nombre - (byDiz * 10);
    strLiaison = '-';
    if (byUnit === 1) {
      strLiaison = ' et ';
    }
    switch (byDiz) {
      case 0: strLiaison = ''; break;
      case 1: byUnit = byUnit + 10; strLiaison = ''; break;
      case 7: byUnit = byUnit + 10; break;
      case 8: strLiaison = '-'; break;
      case 9: byUnit = byUnit + 10; strLiaison = '-'; break;
    }
    let NumDizaine = TabDiz[byDiz];
    if (byDiz === 8 && byUnit === 0) {
      NumDizaine = NumDizaine + 's';
    }
    if (TabUnit[byUnit] !== '') {
      NumDizaine = NumDizaine + strLiaison + TabUnit[byUnit];
    } else {
      NumDizaine = NumDizaine;
    }
    return NumDizaine;
  }

  private static convNumCent(Nombre): string {
    let TabUnit;
    let byCent;
    let byReste;
    let strReste = '';
    let NumCent;
    TabUnit = Array('', 'un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept',
      'huit', 'neuf', 'dix');
    byCent = parseInt((Nombre / 100).toString(), 10);
    byReste = Nombre - (byCent * 100);
    strReste = this.convNumDizaine(byReste);
    switch (byCent) {
      case 0: NumCent = strReste; break;
      case 1:
        if (byReste === 0) {
          NumCent = 'cent';
        } else { NumCent = 'cent ' + strReste; }
        break;
      default:
        if (byReste === 0) {
          NumCent = TabUnit[byCent] + ' cents';
        } else {
          NumCent = TabUnit[byCent] + ' cent ' + strReste;
        }
    }
    return NumCent;
  }
}
