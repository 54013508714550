import { Pays } from './pays.model';
import { Localisation } from './localisation.model';

export class Adresse {
  id: number;
  voie: string;
  complement: string;
  infosEtranger: string;
  pays: Pays;
  localisation: Localisation;
  bp: string;

  public constructor({
    id = null,
    voie = '',
    complement = '',
    infosEtranger = '',
    pays = null,
    localisation = null,
    bp = '',

  }) {
    this.id = id;
    this.voie = voie;
    this.complement = complement;
    this.infosEtranger = infosEtranger;
    this.pays = pays;
    this.localisation = localisation;
    this.bp = bp;
  }
}
