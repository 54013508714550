import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'sirc-lib';
import { environment } from '../../../../../environments/environment';
import { ILogo } from 'projects/sirc-public/src/app/shared/model/demand/logo.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LogoService } from 'projects/sirc-public/src/app/services/crud/logo.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // Version récupérée directement depuis le fichier "package.json"
  public version: string = environment.VERSION;

  public logoSrc: SafeUrl | null;
  public altText: ILogo | null;

  constructor(
    private sanitizer: DomSanitizer,
    private spinnerService: SpinnerService,
    private readonly logoService: LogoService
  ) {}

  ngOnInit(): void {
    this.logoService.getLogo().subscribe({
      next: (logoBlob) => {
        const objectUrl = URL.createObjectURL(logoBlob);
        this.logoSrc = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
      },
      error: () => console.error('An error occurred while fetching the logo.'),
    });

    this.logoService.getAltText().subscribe({
      next: (altText) => (this.altText = altText),
      error: () =>
        console.error('An error occurred while fetching the alt text.'),
    });
  }

  public get spinnerVisible(): boolean {
    return this.spinnerService.spinnerVisible;
  }
}
