import { DemandeEtape } from './../constants/demande-etape.enum';
export class NavigationTab {
  etape: DemandeEtape;
  icon: string;
  name: string;
  display: boolean;
  isAdmin: boolean;
  public constructor(
    etape = null,
    icon = '',
    name = '',
    display = true,
    isAdmin = false
  ) {
    this.etape = etape;
    this.icon = icon;
    this.name = name;
    this.display = display;
    this.isAdmin = isAdmin;
  }
}
