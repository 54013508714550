<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h1>Suivre une demande d'homologation</h1>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      Vous avez la possibilité, à l'issue du délai dont dispose l'administration pour instruire votre demande,
      et si votre demande n'a pas fait l'objet d'un rejet, d'<span class="bold">imprimer une attestation d'homologation</span>.
    </p>
    <p>
      Pour télécharger et imprimer l'attestation, vous devez vous munir du numéro de votre demande (numéro indiqué sur le formulaire ou par le service instructeur)
      et du numéro SIRET de l'entreprise, numéro de cotisant ou du nom du salarié.
    </p>
  </mat-card-content>
</mat-card>

<mat-card class="mt-2">
  <mat-card-header>
    <mat-card-title>
      <h2>Rechercher une demande</h2>
      <p class="hint-form">* les champs sont obligatoires</p>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form" class="d-flex">
      <mat-form-field appearance="outline" class="m-1">
        <mat-label>Numéro de la demande</mat-label>
        <input matInput type="text" formControlName="numero" placeholder="Ex: 201901000012P" required>
        <lib-sirc-icon matSuffix [form]="numeroCtrl" [tooltipText]="'Le numéro de demande TéléRC doit respecter le format suivant : [Année sur 4 chiffres][Mois sur 2 chiffres][Compteur allant de 000000 à 999999][la lettre P, A ou I]'"></lib-sirc-icon>

        <mat-error *ngIf="numeroCtrl.errors?.required">
          <p>Ce champ est obligatoire</p>
        </mat-error>
        <mat-error *ngIf="numeroCtrl.errors?.isNumeroDemande">
          <p>Votre saisie ne respecte pas le format des numéros de demande</p>
        </mat-error>
        <mat-error *ngIf="numeroCtrl.errors?.maxlength">
          <p>Taille maximale : 20 caractères</p>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="m-1">
        <mat-label>Informations salarié ou employeur</mat-label>
        <input matInput type="text" formControlName="info" placeholder="SIRET, numéro de cotisant ou nom du salarié" required>
        <lib-sirc-icon matSuffix [form]="infoCtrl" [tooltipText]="'Vous pouvez saisir le numéro de SIRET ou de cotisant de l\'employeur, ou le nom de famille du salarié concerné.'"></lib-sirc-icon>

        <mat-error *ngIf="infoCtrl.errors?.required || infoCtrl.errors?.isNotBlank">
          <p>Ce champ est obligatoire</p>
        </mat-error>
        <mat-error *ngIf="infoCtrl.errors?.maxlength">
          <p>Taille maximale : 250 caractères</p>
        </mat-error>
      </mat-form-field>

      <div class="mt-2">
        <button mat-button class="mat-primary" [libDisabled]="!form.valid" (click)="searchDemand()">Charger la demande</button>
      </div>
    </form>

    <mat-progress-bar *ngIf="searching" mode="query"></mat-progress-bar>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="demandeRecuperee" class="mt-2">
  <mat-card-header>
    <mat-card-title class="d-flex justify-content-between">
      <h2>Demande</h2>

      <div>
        <button mat-button (click)="openAnotherDemand()">Ouvrir une autre demande</button>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="d-flex demand-data">
      <p><span>Numéro de la demande : </span> {{ demandeRecuperee.numero }}</p>
      <p><span>Employeur : </span> {{ demandeRecuperee.employeurRaisonSociale }}</p>
      <p><span>Salarié(e) : </span> {{ demandeRecuperee.salarieIdentite }}</p>
    </div>

    <div class="d-flex demand-data">
      <p><span>Date de fin du délai d'instruction : </span> {{ demandeRecuperee.dateFinDelaiInstruction | date:'dd/MM/yyyy' }}</p>
      <p><span>Statut de la demande : </span>
        <span [ngClass]="statutClass" class="demand-status">
          <mat-icon *ngIf="statutClass === 'ok'" aria-hidden="true">check_circle</mat-icon>
          <mat-icon *ngIf="statutClass === 'in-progress'" aria-hidden="true">settings_backup_restore</mat-icon>
          <mat-icon *ngIf="statutClass === 'declined'" aria-hidden="true">cancel</mat-icon>
          {{ statutLabel }}
        </span>
      </p>
      <a [attr.href]="donnerAvisUrl" target="_blank">
        <img src="assets/images/svg/bouton-bleu.svg" alt="Je donne mon avis" title="Je donne mon avis sur cette démarche" />
      </a>
    </div>

    <div class="mt-2">
      <div class="d-flex">
        <ng-container *ngTemplateOutlet="download; context: { employer: false, label: 'Imprimer l\'attestation salarié', downloading: downloadingEmployee }"></ng-container>

        <ng-container *ngTemplateOutlet="download; context: { employer: true, label: 'Imprimer l\'attestation employeur', downloading: downloadingEmployer }"></ng-container>
      </div>

      <p class="italic">Les attestations sont téléchargables uniquement si le statut est "Validé" et si la date de fin du délai d'instruction est dépassée (+1 jour).</p>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #download let-employer="employer" let-label="label" let-downloading="downloading">
  <div class="download">
    <button mat-button [libDisabled]="notDownloadable || downloading" (click)="downloadAttestation(employer)">{{ label }}</button>

    <div *ngIf="downloading" class="loading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <p>Récupération du document en cours, veuillez patienter...</p>
    </div>
  </div>
</ng-template>
