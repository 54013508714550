export class Localisation {
    id: number;
    codePostal: string;
    commune: string;
    numeroInsee: string;

    constructor({
      id = null,
      codePostal = '',
      commune = '',
      numeroInsee = '',
    }) {
        this.id = id;
        this.codePostal = codePostal;
        this.commune = commune;
        this.numeroInsee = numeroInsee;
    }
}
