import { Injectable } from '@angular/core';
import {
  Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot
} from '@angular/router';
import { BodyOutputType } from 'angular2-toaster';
import { SircToast, ToasterSharedService } from 'projects/sirc-lib/src/lib/services/shared/toaster-shared.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TYPE_TOAST } from 'sirc-lib';

import { DemandeService } from '../../services/crud/demande.service';
import { TemporaryDemandService } from '../services/temporary-demand.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordDefinitionResolver implements Resolve<boolean> {
  constructor(
    private readonly router: Router,
    private readonly toasterService: ToasterSharedService,
    private readonly demandeService: DemandeService,
    private readonly temporaryDemandService: TemporaryDemandService,
  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (route.params.token && route.params.uuid) {
      // if token and uuid update password
      return this.demandeService.demandPasswordExist(route.params.uuid, route.params.token).pipe(
        tap(exist => this.manageNotExistDemand(exist, true)),
      );
    } else if (route.params.uuid) {
      // if only uuid create demand with password
      return this.temporaryDemandService.demandExist(route.params.uuid).pipe(
        tap(exist => this.manageNotExistDemand(exist, true)),
      );
    }

    // Back to home
    this.toasterService.create(new SircToast(
      TYPE_TOAST.ERREUR,
      'Erreur d\'accès au changement de mot de passe',
      'Vous ne disposez pas des informations nécessaires pour changer de mot de passe de cette demande',
    ));
    this.router.navigate(['accueil']);
    return of(false);
  }

  private manageNotExistDemand(exist: boolean, temprorary: boolean) {
    if (exist) {
      return;
    }

    let title;
    let msg;
    if (temprorary) {
      title = 'Erreur de définition du mot de passe';
      msg = 'La demande temporaire n\'existe pas ou a expiré, veuillez en recréer une';
    } else {
      title = 'Erreur de modification du mot de passe';
      msg = 'La demande n\'a pas de demande de changement de mot de passe ou cette demande à expiré veuillez en recréer une';
    }


    this.toasterService.create(new SircToast(
      TYPE_TOAST.AVERTISSEMENT,
      title,
      msg
    ));
    this.router.navigate(['accueil']);
  }
}
