import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Adresse } from '../../models/adresse.model';
import { Localisation } from '../../models/localisation.model';
import { environment } from 'projects/sirc-public/src/environments/environment';
import { environment as intraEnvironment} from 'projects/sirc-intra/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpAdresseService {

  private readonly END_POINT_COMMUNE = (environment.hrefBase || intraEnvironment.hrefBase) + '/api/referentiel/localisation/autocomplete';

  private readonly END_POINT_ADRESSE = (environment.hrefBase || intraEnvironment.hrefBase) + '/api/adresse';

  constructor(private http: HttpClient) { }

  public villeAutocomplete(query: any): Observable<Localisation[]> {
    return this.http.get<Localisation[]>(this.END_POINT_COMMUNE, { params: { ...query } });
  }

  public autocomplete(query: string): Observable<Adresse[]> {
    return this.http.get<Adresse[]>(`${this.END_POINT_ADRESSE}/autocomplete`, { params: { query } });
  }

  public fetchMinSize(): Observable<number> {
    return this.http.get<number>(`${this.END_POINT_ADRESSE}/config/min-size`);
  }
}
