import { Adresse } from './adresse.model';
import { Ape } from './ape.model';

export class Employeur {
  id: number;
  ape: Ape;
  courriel: string;
  effectif: number;
  nomSignataire: string;
  raisonSociale: string;
  siret: string;
  telephone: string;
  urssaf: string;
  adresse: Adresse;
  adresseCorrespondance: Adresse;

  public constructor({
    id = null,
    ape = null,
    courriel = '',
    effectif = null,
    nomSignataire = '',
    raisonSociale = '',
    siret = '',
    telephone = '',
    urssaf = '',
    adresse = new Adresse({}),
    adresseCorrespondance = new Adresse({}),

  }) {
    this.id = id;
    this.ape = ape;
    this.courriel = courriel;
    this.effectif = effectif;
    this.nomSignataire = nomSignataire;
    this.raisonSociale = raisonSociale;
    this.siret = siret;
    this.telephone = telephone;
    this.urssaf = urssaf;
    this.adresse = adresse ? new Adresse(adresse) : new Adresse({});
    this.adresseCorrespondance = adresseCorrespondance ? new Adresse(adresseCorrespondance) : new Adresse({});
  }

}
