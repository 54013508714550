import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DemandeEtape } from 'sirc-lib';
import { NavigationTab } from '../../../models/navigation-tab.model';
import { DemandeSharedService } from '../../../services/shared/demande-shared.service';

@Component({
  selector: 'lib-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Input()
  public isBo = false;

  @Input()
  public tabs: NavigationTab[];

  @Output()
  public changeStepEvent = new EventEmitter<NavigationTab>();

  @ViewChild('previousBtn', { static: false })
  public previousBtn: MatButton;

  @ViewChild('nextBtn', { static: false })
  public nextBtn: MatButton;

  public form: FormGroup;

  private currentTab: NavigationTab;
  private subscription: Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly demandeSharedService: DemandeSharedService,
  ) { }

  ngOnInit(): void {
    this.form = this.demandeSharedService.form;

    this.setCurrentStep(this.router.url);

    // update current step to navigate
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.setCurrentStep(event.url));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Show tooltip if the form is enabled
   */
  public showTooltip(etape: DemandeEtape): string {
    if (this.form.get(etape).disabled) { return ''; }
    if (this.form.get(etape).valid) { return 'L\'onglet est valide'; }
    return 'L\'onglet n\'est pas valide';
  }

  /**
   * If the step is valid then a green check is displayed
   */
  public isEtapeValid(etape: DemandeEtape): boolean {
    return this.form.get(etape) ? (this.form.get(etape).valid || this.form.get(etape).disabled) : true;
  }

  /**
   * Display indicator if the form is touched/valid/disabled
   * Do not display indicator if displayed is false
   */
  public isEtapeDisplayed(displayed: boolean, etape: DemandeEtape): boolean {
    return displayed &&
      (this.form.get(etape) ? (this.form.get(etape).touched || this.form.get(etape).valid || this.form.get(etape).disabled) : true);
  }

  /**
   * Exception: hide transmission tab if cerfa is not printed for TeleRC
   */
  public isEtapeHidden(etape: string): boolean {
    return !this.isBo && etape === DemandeEtape.TRANSMISSION && !this.form.get('validation').get('dateImpression').value;
  }

  /**
   * Check if the current tab is selected
   */
  public isCurrentTab(etape: string): boolean {
    return document.getElementById(`tabpanel-${etape}`).classList.contains('is-active');
  }

  public hasPrevious(): boolean {
    return (this.tabs.indexOf(this.currentTab) - 1) > -1;
  }

  public hasNext(): boolean {
    return (this.tabs.indexOf(this.currentTab) + 1) < this.tabs.length;
  }

  public isNextEtapeHidden(): boolean {
    return !this.isBo && this.currentTab.etape === DemandeEtape.VALIDATION && !this.form.get('validation').get('dateImpression').value;
  }

  public previousStep(): void {
    this.changeStep(-1);

    if (this.nextBtn) {
      this.nextBtn.focus();
    }
  }

  public nextStep(): void {
    this.changeStep(1);

    if (this.previousBtn) {
      this.previousBtn.focus();
    }
  }

  public get currentTabName(): string {
    return this.currentTab.etape;
  }

  private changeStep(increment: number): void {
    const newIndex = this.tabs.indexOf(this.currentTab) + increment;

    if (newIndex > -1 && newIndex < this.tabs.length) {
      this.router.navigate([{ outlets: { etape: [this.tabs[newIndex].etape] } }], { relativeTo: this.route });
    }
  }

  private setCurrentStep(url: string): void {
    const indexOutlet = url.indexOf('etape:');

    this.currentTab = indexOutlet > -1
      ? this.tabs.find((tab) => tab.etape === url.substring(indexOutlet + 6, url.length - 1))
      : this.tabs[0];

    // RGAA 12.8 : consistent tabulation order
    setTimeout(() => this.setActionsTabindex());

    // RGAA 12.8 : set focus on current tab
    setTimeout(() => document.getElementById(`tabpanel-${this.currentTab.etape}`)?.focus());

    this.changeStepEvent.emit(this.currentTab);
  }

  private setActionsTabindex(): void {
    if (this.isBo || (!this.hasNext() && !this.isNextEtapeHidden())) {
      document.getElementById('actions').setAttribute('tabindex', '0');
    } else {
      document.getElementById('actions').setAttribute('tabindex', '-1');
    }
  }
}
