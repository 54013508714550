import { QualiteAssistant } from './qualite-assistant.model';
import { Ape } from './ape.model';
import { ConventionCollective } from './convention-collective.model';
import { Pays } from './pays.model';
import { Qualification } from './qualification.model';
import { OrganismeMiniDto } from './organisme-mini-dto.dto';
import { Signature } from './signature.model';
import { TypeCourrier } from './type-courrier.model';
import { Statut } from './statut.model';

export class DemandeResolvedData {
  public apes: Ape[];
  public pays: Pays[];
  public qualifications: Qualification[];
  public conventionCollectives: ConventionCollective[];
  public qualites: QualiteAssistant[];
  public listeOrganismesAccessibles: OrganismeMiniDto[];
  public listeSignature?: Signature[];
  public listeTypeCourrier?: TypeCourrier[];
  public listeStatut?: Statut[];


  public constructor({
    apes = [],
    pays = [],
    qualifications = [],
    conventionCollectives = [],
    qualites = [],
    listeOrganismesAccessibles = [],
    listeSignature = [],
    listeTypeCourrier = [],
    listeStatut = [],
  }) {
    this.apes = apes;
    this.pays = pays;
    this.qualifications = qualifications;
    this.conventionCollectives = conventionCollectives;
    this.qualites = qualites;
    this.listeOrganismesAccessibles = listeOrganismesAccessibles;
    this.listeSignature = listeSignature;
    this.listeTypeCourrier = listeTypeCourrier;
    this.listeStatut = listeStatut;
  }
}
