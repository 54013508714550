import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  private _showSpinner = false;

  constructor() { }

  /**
   * Return value for show spinner
   */
  public get spinnerVisible(): boolean {
    return this._showSpinner;
  }

  /**
   * Show spinner
   */
  public showSpinner(): void {
    this._showSpinner = true;
  }

  /**
   * Hide spinner
   */
  public hideSpinner(): void {
    this._showSpinner = false;
  }
}
