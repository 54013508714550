import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Remuneration } from './../../models/remuneration.model';
import { AncienneteFormBuilder } from './anciennete-form.builder';
import { SalaireFormBuilder } from './salaire-form.builder';

export class RemunerationFormBuilder {
  constructor(
    private fb: FormBuilder,
    private remuneration: Remuneration,
    private isDisabled: boolean,
  ) { }

  build(): FormGroup {
    return this.fb.group({
      id: this.fb.control({
        value: this.remuneration.id,
        disabled: true,
      }),

      anciennete: new AncienneteFormBuilder(this.fb, this.remuneration, this.isDisabled).build(),

      salaire: new SalaireFormBuilder(this.fb, this.remuneration, this.isDisabled).build(),

      moyenneRemunerationMensuelle: this.fb.control({
        value: this.remuneration.moyenneRemunerationMensuelle,
        disabled: this.isDisabled
      },
        [Validators.required, Validators.min(0), Validators.pattern(/^[0-9]+([.,]{0,1}[0-9]+){0,1}$/)]
      ),

      moyenneRemunerationMensuelleAutomatique: this.fb.control({
        value: 0,
        disabled: this.isDisabled
      }),

      indemnite: this.fb.control({
        value: this.remuneration.indemnite,
        disabled: this.isDisabled
      },
        [Validators.required, Validators.min(0), Validators.pattern(/^[0-9]+([.,]{0,1}[0-9]+){0,1}$/)]
      ),

      indemniteLegale: this.fb.control({
        value: this.remuneration.indemniteLegale,
        disabled: this.isDisabled
      },
        [Validators.min(0), Validators.pattern(/^[0-9]+([.,]{0,1}[0-9]+){0,1}$/)]
      ),

      indemniteConventionnelle: this.fb.control({
        value: this.remuneration.indemniteConventionnelle,
        disabled: this.isDisabled
      },
        [Validators.min(0), Validators.pattern(/^[0-9]+([.,]{0,1}[0-9]+){0,1}$/)]
      ),

      commentaire: this.fb.control({
        value: this.remuneration.commentaire,
        disabled: this.isDisabled
      },
        [Validators.maxLength(10000)]
      ),
    });
  }
}
