export enum ArticleTypeEnum {
  // TeleRC
  GUIDE = 'GUIDE',
  FAQ = 'FAQ',
  RUPTURE = 'RUPTURE',
  GENERAL_CONDITION = 'GENERAL_CONDITION',
  // IntraRC
  FAQ_LEGAL = 'FAQ_LEGAL',
  FAQ_FUNCTIONAL = 'FAQ_FUNCTIONAL',
  DOCUMENTS = 'DOCUMENTS',
  CONTACT = 'CONTACT',
  GUIDE_INTRA = 'GUIDE_INTRA',
}
