import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-confirm-modale',
  template: `
  <div class="confirm-dialog" role="dialog" aria-modal="true" tabindex="-1">
    <div class="dialog-header d-flex justify-content-between align-items-baseline">
      <h3 *ngIf="titre" mat-dialog-title>{{ titre }}</h3>
      <button type="button" mat-flat-button aria-label="Fermer" title="Fermer la fenêtre modale" data-dismiss="dialog"
        class="close-button border-0" (click)="close()">
        <mat-icon aria-hidden="true">close</mat-icon>
      </button>
    </div>
    <mat-dialog-content>
      <p [innerHTML]="contenu"></p>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-end">
      <button *ngIf="!cacheBoutonNon && (!isSubmitting || noSubmitting)" mat-button mat-dialog-close class="mr-1">{{ texteBoutonNon }}</button>
      <button *ngIf="!cacheBoutonOui && (!isSubmitting || noSubmitting)" mat-button (click)="clickOui()" cdkFocusInitial>{{ texteBoutonOui }}</button>
      <mat-progress-bar mode="indeterminate" *ngIf="isSubmitting && !noSubmitting"></mat-progress-bar>
    </mat-dialog-actions>
  </div>`,
})
export class ConfirmModaleComponent {
  public isSubmitting = false;

  // Paramètres
  public titre = '';
  public contenu = '';
  public texteBoutonNon = 'Annuler';
  public texteBoutonOui = 'Confirmer';
  public cacheBoutonNon = false;
  public cacheBoutonOui = false;
  public classeBoutonOui = 'success';
  public noSubmitting = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmModaleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.titre !== undefined) { this.titre = data.titre; }
    if (data.contenu !== undefined) { this.contenu = data.contenu; }
    if (data.texteBoutonNon !== undefined) { this.texteBoutonNon = data.texteBoutonNon; }
    if (data.texteBoutonOui !== undefined) { this.texteBoutonOui = data.texteBoutonOui; }
    if (data.cacheBoutonNon !== undefined) { this.cacheBoutonNon = data.cacheBoutonNon; }
    if (data.cacheBoutonOui !== undefined) { this.cacheBoutonOui = data.cacheBoutonOui; }
    if (data.classeBoutonOui !== undefined) { this.classeBoutonOui = data.classeBoutonOui; }
    if (data.noSubmitting !== undefined) { this.noSubmitting = data.noSubmitting; }
  }

  public clickOui(): void {
    this.dialogRef.close(true);
  }

  public close(): void {
    this.dialogRef.close(undefined);
  }
}
