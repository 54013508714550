<div class="footer d-flex justify-content-between bg-white">
  <div class="menu h-100">
    <ul>
      <li><a routerLink="/espace-documentaire/rupture-conventionnelle">Information sur la rupture conventionnelle</a></li>
      <li><a routerLink="/espace-documentaire/interlocuteur">Mon interlocuteur en région</a></li>
      <li><a routerLink="/espace-documentaire/faq" title="Foire aux questions">FAQ</a></li>
      <li><a routerLink="/espace-documentaire/visite-guidee">Visite guidée / Mode d'emploi</a></li>
      <li><a routerLink="/espace-documentaire/conditions-generales">Accessibilité : partiellement conforme / Contacts / Mentions Légales</a></li>
    </ul>
  </div>

  <div class="plan-relance h-100">
    <img class="fr" alt="France relance" src="assets/images/plan_relance/france_relance.png" />
    <img class="ue" alt="Financé par l'union eurpéenne" src="assets/images/plan_relance/next_generation_eu.png" />
  </div>
</div>
