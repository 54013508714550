import { EntretienFormBuilder } from './entretien-form.builder';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Demande } from '../../models/demande.model';
import { Entretien } from '../../models/entretien.model';
import { DatesValidator } from '../../validators/dates.validator';

export class CalendrierFormBuilder {
  constructor(
    private fb: FormBuilder,
    private demande: Demande,
    private isDisabled: boolean,
  ) { }

  build(): FormGroup {
    return this.fb.group({

      entretiens: this.fb.array(
        this.demande.entretiens.map((entretien: Entretien) => new EntretienFormBuilder(
          this.fb,
          entretien,
          this.isDisabled,
        ).build())
      ),

      remarqueEntretiens: this.fb.control(
        {
          value: this.demande.remarqueEntretiens,
          disabled: this.isDisabled,
        },
        [Validators.maxLength(10000)]
      ),

      dateSignature: this.fb.control(
        {
          value: this.demande.dateSignature,
          disabled: this.isDisabled,
        },
        [Validators.required]
      ),

      dateFinDelaiRetractation: this.fb.control(
        {
          value: this.demande.dateFinDelaiRetractation,
          disabled: this.isDisabled,
        }
      ),

      dateFinDelaiInstruction: this.fb.control(
        {
          value: this.demande.dateFinDelaiInstruction,
          disabled: this.isDisabled,
        }
      ),

      dateEnvisageeRupture: this.fb.control(
        {
          value: this.demande.dateEnvisageeRupture,
          disabled: this.isDisabled,
        },
        [Validators.required]
      ),

      autresClauses: this.fb.control(
        {
          value: this.demande.autresClauses,
          disabled: this.isDisabled,
        },
        [Validators.maxLength(10000)]
      ),
    },
      {
        validators: [
          DatesValidator.isDateEnvisageeRuptureValide,
          DatesValidator.isDateSignaturePosterieurEntretien
        ]
      }
    );
  }
}
