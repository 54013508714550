// CLASSES DTO "IMPRESSION CERFA" (demande)
export class ImpressionCerfaDto {
  uuid: string;
  indemniteLettres: string;
  isCerfaOfficiel: boolean;
  password: string;

  public constructor({
    uuid = '',
    indemniteLettres = '',
    isCerfaOfficiel = true,
    password = '',
  }) {
    this.uuid = uuid;
    this.indemniteLettres = indemniteLettres;
    this.isCerfaOfficiel = isCerfaOfficiel;
    this.password = password;
  }
}
