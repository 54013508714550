import { Component } from '@angular/core';

@Component({
  selector: 'app-avoidance-link',
  templateUrl: './avoidance-link.component.html',
  styleUrls: ['./avoidance-link.component.scss'],
})
export class AvoidanceLinkComponent {

  public focus = false;

  constructor() { }

}
