import { AbstractBreadcrumbService } from 'sirc-lib';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { Subscription } from 'rxjs';
import { BreadcrumbItem } from '../../utils/breadcrumb-item.model';

@Component({
  selector: 'lib-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  private subscribe: Subscription;
  public items: BreadcrumbItem[];

  constructor(
    @Inject('BreadcrumbSharedService') private breadcrumbSharedService: AbstractBreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.subscribe = this.breadcrumbSharedService.observable.subscribe((items) => {
      this.items = items;
    });
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  public get isEmpty(): boolean {
    return !this.items || this.items.length === 0;
  }
}
