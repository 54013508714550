import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { AbastractValidator } from './abstract.validator';

export class DatesValidator extends AbastractValidator {

  /**
   * The signature date is same or after the first interview date
   */
  static isDateSignaturePosterieurEntretien(control: AbstractControl): ValidationErrors | null {
    const dateEntretiens = DatesValidator.getDateEntretiensFromControl(control);
    const signatureCtrl = control.get('dateSignature');
    if (signatureCtrl?.value && dateEntretiens?.length > 0
      && moment(signatureCtrl.value).isBefore(moment(dateEntretiens[0]), 'day')) {
      signatureCtrl.setErrors({ signatureIsSameOrAfterInterview: 'false' });
      return { signatureIsSameOrAfterInterview: 'false' };
    }
    super.removeError(signatureCtrl, 'signatureIsSameOrAfterInterview');
    return null;
  }

  /**
   * The envisaged rupture date is after the dealine of instruction
   * And  is after the signature date
   */
  static isDateEnvisageeRuptureValide(control: AbstractControl): ValidationErrors | null {
    const dateEnvisageeRupCtrl = control.get('dateEnvisageeRupture');
    const dateFinDelaiIns = control.get('dateFinDelaiInstruction')?.value;
    const dateSignatureVal = control.parent?.get('dateSignature')?.value;

    if (dateEnvisageeRupCtrl?.value && dateFinDelaiIns) {
      if (moment(dateEnvisageeRupCtrl.value).isSameOrBefore(moment(dateFinDelaiIns), 'day')) {
        dateEnvisageeRupCtrl.setErrors({ envisageeRuptureIsAfterInstruction: 'false' });
        return { envisageeRuptureIsAfterInstruction: 'false' };
      } else {
        super.removeError(dateEnvisageeRupCtrl, 'envisageeRuptureIsAfterInstruction');
      }
    }

    if (dateEnvisageeRupCtrl?.value && dateSignatureVal) {
      if (moment(dateEnvisageeRupCtrl.value).isSameOrBefore(moment(dateSignatureVal), 'day')) {
        dateEnvisageeRupCtrl.setErrors({ envisageeRuptureIsAfterSignature: 'false' });
        return { envisageeRuptureIsAfterSignature: 'false' };
      } else {
        super.removeError(dateEnvisageeRupCtrl, 'envisageeRuptureIsAfterSignature');
      }
    }
    return null;
  }

  /**
   * Print cerfa is done
   */
  static isValidationDone(control: AbstractControl): ValidationErrors | null {
    if (!control.value) { return { isValidationDone: 'false' }; }
    return null;

  }

  /**
   * Teletransmission is done
   */
  static isTransmissionDone(control: AbstractControl): ValidationErrors | null {
    if (!control.value) { return { isTransmissionDone: 'false' }; }
    return null;

  }

  /**
   * Paper demand: reception date is after send date
   * Teletransmission: reception date is same or after send date
   * Since send date is not stored in DB, compare with the withdrawl date instead
   */
  static isDateReceptionValide(control: AbstractControl): ValidationErrors | null {
    const dateReceptionCtrl = control.get('gestion').get('dateReception');
    const dateFinDelaiRetractationCtrl = control.get('calendrier').get('dateFinDelaiRetractation');
    const isPapierCtrl = control.get('isPapier');

    if (dateReceptionCtrl.value && dateFinDelaiRetractationCtrl.value) {
      const dateR = moment(dateReceptionCtrl.value);
      const dateFnDR = moment(dateFinDelaiRetractationCtrl.value);
      if (isPapierCtrl.value && dateR.isSameOrBefore(dateFnDR, 'day') || (!isPapierCtrl.value && dateR.isBefore(dateFnDR, 'day'))) {
        dateReceptionCtrl.setErrors({ dateReceptionInvalid: 'true' });
        return { dateReceptionInvalid: 'true' };
      }
    }
    super.removeError(dateReceptionCtrl, 'dateReceptionInvalid');
    return null;
  }

  /**
   * Return a list of interviews dates
   */
  private static getDateEntretiensFromControl(control: AbstractControl): ValidationErrors | null {
    return control.get('entretiens')?.value
      .filter(entretien => entretien.date)
      .map(entretien => entretien.date)
      .sort();
  }
}
