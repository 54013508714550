<mat-form-field appearance="outline" [formGroup]="form">
  <mat-label>{{ label }}</mat-label>

  <input type="text" matInput [formControlName]="dateControlName" [matDatepicker]="picker" [matDatepickerFilter]="datePickerFilter"
    [min]="minDate" [max]="maxDate" placeholder="Ex: 01/01/2001" [required]="isRequired"
    (dateChange)="dateChange($event)">

  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker (opened)="openDatePicker()"></mat-datepicker>

  <!-- Help Icon -->
  <lib-sirc-icon matSuffix *ngIf="enableHelp" [tooltipText]="tooltipText" [form]="dateControl"></lib-sirc-icon>

  <mat-error *ngIf="dateControl.hasError('required')"><p>Ce champ est obligatoire.</p></mat-error>
  <mat-error
    *ngIf="!dateControl.hasError('required') && (dateControl.hasError('matDatepickerParse') || dateControl.hasError('matDatepickerFilter'))">
    <p>Veuillez saisir une date dans le passé et au format dd/mm/aaaa</p>
  </mat-error>

  <mat-error>
    <ng-content></ng-content>
  </mat-error>

</mat-form-field>
