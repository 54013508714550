import { Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SessionService {
  private isAuthenticated$ = new Subject<boolean>();

  private _token: string;

  /**
   * Get an observable to know if the user is authenticated
   */
  public get isAuthenticated(): Observable<boolean> {
    return this.isAuthenticated$.asObservable();
  }

  public get isLogged(): boolean {
    return !!this._token;
  }

  public set token(value: string) {
    this._token = value;
    this.isAuthenticated$.next(value !== null);
  }

}
