import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToasterSharedService } from 'projects/sirc-lib/src/lib/services/shared/toaster-shared.service';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Demande, SpinnerService, TYPE_TOAST } from 'sirc-lib';
import { DemandeService } from '../../..//services/crud/demande.service';
import { DemandeAuthenticationService } from '../../../core/services/demand-authentication.service';
import { isNumeroDemandePublic } from '../../../directives/validators/numero-demande.validators';
import { ConfigurationService } from '../../../services/configuration.service';
import { LoggedDemand } from '../../../shared/model/demand/logged-demand.model';
import { TitleService, TitleState } from '../../../shared/service/title.service';
import { ReCaptcha2Component } from 'ngx-captcha';
export interface DialogData {
  edit: boolean;
  uuid: string;
  passwordForgot: boolean;
}
@Component({
  selector: 'app-dialog-edit-demand',
  templateUrl: './dialog-edit-demand.component.html',
  styleUrls: ['./dialog-edit-demand.component.scss']
})
export class DialogEditDemandComponent implements OnInit {
  public hide = true;

  public edit = false;

  public passwordForgot = false;

  public captchaSiteKey: string;

  /**
   * Demande number if accessed from a demand link
   */
  public demandeNumber: string;

  public editForm: FormGroup;
  public passwordForm: FormGroup;
  public isSubmitting = false;

  public patternsNumeroDemand = {
    A: { pattern: /[Pp]/ },
    0: { pattern: /\d/ }
  };

  @ViewChild("captcha") captchaRef: ReCaptcha2Component;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: DialogData,
    private readonly dialogRef: MatDialogRef<DialogEditDemandComponent>,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly toasterService: ToasterSharedService,
    private readonly configService: ConfigurationService,
    private readonly demandeService: DemandeService,
    private readonly demandeAuthenticationService: DemandeAuthenticationService,
    private readonly spinnerService: SpinnerService,
    private readonly titleService: TitleService,
  ) {
    if (this.data.uuid !== undefined) this.getDemandeNumber(this.data.uuid);
    if (this.data.passwordForgot !== undefined) this.passwordForgot = this.data.passwordForgot;

    this.edit = this.data.edit;
    this.captchaSiteKey = this.configService.getEnvironment().captcha.siteKey;
  }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      email: this.formBuilder.control('', [Validators.required, Validators.email, Validators.maxLength(250)]),
      numero: this.formBuilder.control('', [Validators.required, Validators.maxLength(20), isNumeroDemandePublic()]),
      password: this.formBuilder.control('', [Validators.required]),
    });

    this.passwordForm = this.formBuilder.group({
      email: this.formBuilder.control('', [Validators.required, Validators.email, Validators.maxLength(250)]),
      numero: this.formBuilder.control('', [Validators.required, Validators.maxLength(20), isNumeroDemandePublic()]),
      recaptcha: this.formBuilder.control('', [Validators.required]),
    });
  }

  /**
   * Get demande number
   * @param uuid UUID
   */
  getDemandeNumber(uuid: string): void {
    this.spinnerService.showSpinner();
    this.demandeService.getByUuidUnauth(uuid).subscribe(
      (demande: Demande) => {
        this.demandeNumber = demande.numero;
        this.passwordForm.get('numero').setValue(this.demandeNumber);
        this.spinnerService.hideSpinner();
      },
      () => {
        this.toasterService.create({
          type: TYPE_TOAST.ERREUR,
          title: 'Demande introuvable',
          body: 'La demande correspondante n\'a pas été trouvée. Veuillez vérifier le lien utilisé.',
        });
        this.spinnerService.hideSpinner();
        this.dialogRef.close();
      }
    );
  }

  public get title(): string {
    if (this.passwordForgot) {
      return 'Demande de changement de mot de passe';
    }

    return this.edit ? 'Compléter une demande' : 'Transmettre une demande';
  }

  public get emailCtrl(): FormControl {
    const form = this.passwordForgot ? this.passwordForm : this.editForm;
    return form.get('email') as FormControl;
  }

  public get numeroCtrl(): FormControl {
    const form = this.passwordForgot ? this.passwordForm : this.editForm;
    return form.get('numero') as FormControl;
  }

  public get passwordCtrl(): FormControl {
    return this.editForm.get('password') as FormControl;
  }

  public get recaptchaCtrl(): FormControl {
    return this.passwordForm.get('recaptcha') as FormControl;
  }

  public submitNewPassword(): void {
    this.titleService.changeTitle(TitleState.DEMANDE_UPDATE);
    if (this.passwordForm.invalid) {
      return;
    }

    this.isSubmitting = true;
    this.passwordForm.disable();
    this.demandeService.resetPassword(this.emailCtrl.value, this.numeroCtrl.value, this.recaptchaCtrl.value)
      .subscribe(
        {
          next: () => {
              this.isSubmitting = false;
              this.toasterService.create({
                type: TYPE_TOAST.SUCCES,
                title: 'Demande de nouveau mot de passe envoyée',
                body: 'Si une demande correspond aux informations saisies vous allez recevoir un mail de réinitialisation du mot de passe de cette demande.',
              });

              this.dialogRef.close();
          },
          error: () => {
            this.isSubmitting = false;
            this.passwordForm.enable();
            this.captchaRef.resetCaptcha();
            this.toasterService.create({
              type: TYPE_TOAST.ERREUR,
              title: 'Demande de nouveau mot de passe impossible',
              body: 'Une erreur est survenue lors de la demande de nouveau mot de passe, l\'email fourni ne correspond pas à celui demandé. Veuillez réessayer.',
            });
          }
        }
      );
  }

  /**
   * Login demand
   */
  public submitEditDemande(): void {
    this.titleService.changeTitle(TitleState.DEMANDE_UPDATE);
    if (this.editForm.invalid) {
      return;
    }

    this.spinnerService.showSpinner();
    this.isSubmitting = true;
    this.editForm.disable();
    this.demandeAuthenticationService.login(this.numeroCtrl.value, this.emailCtrl.value, this.passwordCtrl.value)
      .subscribe((loggedDemand: LoggedDemand) => {
        if (loggedDemand) {
          if (!this.edit && !loggedDemand.dateImpression) { // Modale "Transmettre" + dateImpression nulle
            this.toasterService.create({
              type: TYPE_TOAST.AVERTISSEMENT,
              title: 'Demande en cours de saisie',
              body: `La demande correspondante est en cours de saisie, et n'est pas prête à être transmise à l'administration.
            Vous allez être redirigé vers le formulaire pour terminer votre saisie.`,
            });
          }

          this.router.navigate(['/demandes/gestion', loggedDemand.uuid]).then(() => this.spinnerService.hideSpinner());
          this.dialogRef.close();
        }
      }, () => {
        this.titleService.changeTitle({ value: TitleState.INVALID_CREDENTIALS_ERROR.value + TitleState.DEMANDE_UPDATE.value });
        this.toasterService.create({
          type: TYPE_TOAST.AVERTISSEMENT,
          title: 'Demande introuvable',
          body: `Aucune demande ne semble correspondre aux identifiants saisis (numéro ET courriel).
        Veuillez vérifier votre saisie et réessayer.`,
        });

        this.spinnerService.hideSpinner();
        this.isSubmitting = false;
        this.editForm.enable();
      });
  }
}
