<div>
  <mat-card class="mb-1">
    <mat-card-header>
      <mat-card-title>
        <h2>Quelle indemnité légale ?</h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="italic">
        <p>
          A l’occasion de la rupture conventionnelle de son contrat de travail,
          le salarié doit percevoir une «indemnité spécifique de rupture conventionnelle» dont le montant,
          éventuellement négocié avec l’employeur, ne peut être inférieur :
        </p>
        <p class="ml-2">
          - soit, à l’indemnité légale de licenciement, soit, 1/4 de mois de salaire par année d’ancienneté pour les années jusqu'à 10 ans et 1/3 de mois
          de salaire par année d'ancienneté pour les années à partir de 10 ans (article R. 1234-2 du code du travail).
        </p>
        <p class="ml-2">
          - soit, à l’indemnité de licenciement prévue par la convention collective, lorsque cette dernière est plus élevée que l’indemnité légale.
          Il vous appartient de vérifier les modalités d’application de cette indemnité.
        </p>
      </div>
    </mat-card-content>
  </mat-card>

  <lib-indemnite [mode]="ModeIndeminte.SIMULATOR"></lib-indemnite>
</div>
