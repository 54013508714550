import { Injectable } from '@angular/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
@Injectable({providedIn: 'root'})
export class SircDatepickerFr extends MatDatepickerIntl {
  constructor() {
    super();
    this.closeCalendarLabel = 'Fermer le calendrier';
    this.nextMonthLabel = 'Aller au mois suivant';
    this.prevMonthLabel = 'Aller au mois précédent';
    this.nextYearLabel = 'Aller à l\'année suivante';
    this.prevYearLabel = 'Aller à l\'année précédente';
    this.nextMultiYearLabel = 'Aller au 24 années suivantes';
    this.prevMultiYearLabel = 'Aller au 24 années précédentes';
    this.switchToMonthViewLabel = 'Choisir une date';
    this.switchToMultiYearViewLabel = 'Choisir une année';
   }
}
