<header class="sirc-header" role="banner">
  <app-avoidance-link></app-avoidance-link>
  <app-header></app-header>
</header>

<main id="contenu" class="p-1" role="main">
  <toaster-container [toasterconfig]="appConfig"></toaster-container>
  <nav aria-label="Fil d'ariane">
    <lib-breadcrumb></lib-breadcrumb>
  </nav>
  <router-outlet></router-outlet>
</main>

<footer id="pied-de-page" role="contentinfo">
  <app-footer class="sirc-main-footer"></app-footer>
</footer>
