import { FormGroup } from '@angular/forms';
import { Localisation } from '../models/localisation.model';
export class LocalisationUtils {

  /**
   * Get localisation of employer or employee
   * If the employer is in France, take the employer's department to retrieve referer organism
   * Otherwise, take the employee's department for referer organism
   */
  public static getLocalisation(employeurAdresse: FormGroup, salarieAdresse: FormGroup): Localisation {
    // Get localistation of employer
    let localisationForm = employeurAdresse.get('localisation') as FormGroup;
    if (!localisationForm || !localisationForm.value) {
      // If localisation of employer is null, get localisation of employee
      localisationForm = salarieAdresse.get('localisation') as FormGroup;
    }
    if (localisationForm && localisationForm.value) {
      return new Localisation(localisationForm.value);
    }
    return null;
  }

}
