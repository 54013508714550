<div class="home">
  <div class="container-fluid">
    <div class="row">
      <div class="col-9 p-0">
        <!-- Message d'information-->
        <lib-message-info [messageInfo]="messageInfo"></lib-message-info>
        <!-- Introduction -->
        <mat-card class="introduction">
          <mat-card-header>
            <mat-card-title>
              <h1>Texte d'introduction au service</h1>
            </mat-card-title>
          </mat-card-header>

          <mat-card-content class="introduction-content">
            <p>
              La rupture conventionnelle permet à l'employeur et au salarié de rompre le contrat de travail
              d'un commun accord. Pour être valide, cette rupture conventionnelle doit être
              <span class="bold">homologuée par l'administration</span>.
            </p>
            <p>
              Employeur, salarié(e), TéléRC vous permet d'effectuer une demande d'homologation de rupture
              conventionnelle d'un(e) salarié(e) en contrat à durée indéterminée (CDI).
            </p>
            <p>
              La saisie assistée vous offre
              <span class="bold">une garantie de qualité de remplissage de votre dossier et un traitement rapide de
                votre
                demande</span>
              par l'administration. Cette opération ne vous prendra que quelques minutes.
              Nous vous invitons à consulter <a routerLink="/espace-documentaire/visite-guidee">l'espace
                documentaire</a>,
              notamment
              la visite guidée, qui vous permet de visualiser les étapes de la saisie.
            </p>

            <div class="demand-actions">
              <button mat-button class="icon-text-link"
                (click)="createDemand()">
                <mat-icon aria-hidden="true">add</mat-icon>
                Créer une nouvelle demande
              </button>

              <button mat-button class="icon-text-link"
                (click)="editDemand(true)">
                <mat-icon aria-hidden="true">create</mat-icon>
                Compléter une demande existante
              </button>

              <button mat-button class="icon-text-link"
                (click)="editDemand(false)">
                <mat-icon aria-hidden="true">send</mat-icon>
                Transmettre une demande existante
              </button>
            </div>
          </mat-card-content>
        </mat-card>

        <!--Approval-->
        <div class="suivi-homologation mt-1">
          <a class="icon-text-link" routerLink="/demandes/suivi-homologation">
            <mat-icon aria-hidden="true">find_in_page</mat-icon>
            <span>Suivre une demande d'homologation</span>
          </a>

          <p class="follow">Vous pourrez y suivre le statut de votre demande d'homologation. Vous pourrez
            également y télécharger et imprimer (format pdf)
            une attestation d'homologation à l'issue du délai d'instruction (15 jours ouvrables après réception de
            votre
            demande).
          </p>
        </div>
      </div>

      <div class="col-3 pr-0">
        <!-- More -->
        <mat-card class="more">
          <mat-card-header>
            <mat-card-title class="mt-1">
              <h2>Voir aussi</h2>
            </mat-card-title>
          </mat-card-header>

          <mat-card-content class="more-content d-flex flex-column">
            <!-- Links -->
            <ul>
              <li class="mt-1">
                <a class="icon-text-link" routerLink="/simulateur/calendrier">
                  <mat-icon aria-hidden="true">event_note</mat-icon>
                  <span>Faire une simulation pour connaitre les délais de rétractation et d'homologation</span>
                </a>
              </li>
              <li class="mt-1">
                <a class="icon-text-link" routerLink="/simulateur/indemnite">
                  <mat-icon aria-hidden="true">euro_symbol</mat-icon>
                  <span>Faire une simulation pour connaitre le montant de l'indemnité légale</span>
                </a>
              </li>
              <li class="mt-1 mb-2">
                <a class="icon-text-link" routerLink="/espace-documentaire/rupture-conventionnelle">
                  <mat-icon aria-hidden="true">info_outline</mat-icon>
                  <span>Tout savoir sur la rupture conventionnelle</span>
                </a>
              </li>
            </ul>
            <div class="mb-2">
              <mat-divider></mat-divider>
            </div>
            <!-- Widget CDT numérique -->
            <div id="widget-cdt-numerique" class="cdt-num-widget d-flex justify-content-center mb-2">
              <iframe src="https://code.travail.gouv.fr/widget.html"
                title="Widget de recherche dans le code du travail numérique"></iframe>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
