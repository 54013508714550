import { AbstractControl } from '@angular/forms';

export class AbastractValidator {
  /**
   * Remove single specific error
   */
  public static removeError(control: AbstractControl, error: string): void {

    const err = control.errors; // get control errors
    if (err) {
      delete err[error]; // delete your own error
      if (!Object.keys(err).length) { // if no errors left
        control.setErrors(null); // set control errors to null making it VALID
      } else {
        control.setErrors(err); // controls got other errors so set them back
      }
    }
  }
}
