<form [formGroup]="adresseForm">
  <div class="container-fluid">
    <div class="row">
      <div class="col-5">
        <mat-form-field appearance="outline">
          <mat-label>Saisissez le pays</mat-label>

          <mat-select formControlName="pays" [compareWith]="compareFunction" (selectionChange)="adressePaysChange()"  required>
            <mat-option *ngFor="let pays of listePays" [value]="pays" [title]="pays.intitule">
              {{pays.intitule}}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="adressePays.hasError('required')">
            <p>Ce champ est obligatoire</p>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="selectCountry">
      <!-- Address for French-->
      <div *ngIf="isCountryFrance; else notUseBan">
        <lib-autocomplete-adresse-fr [formAdresse]="adresseForm"></lib-autocomplete-adresse-fr>
      </div>

      <!-- Address for other countries-->
      <ng-template #notUseBan>
        <div class="row">
          <div class="col-7">
            <!-- Numéro, voie-->
            <mat-form-field appearance="outline">
              <mat-label>Numéro, voie</mat-label>

              <input type="text" matInput formControlName="voie" required>

              <mat-error *ngIf="adresseVoie.hasError('required')">
                <p>Ce champ est obligatoire</p>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-5">
            <!--Commune-->
            <mat-form-field appearance="outline">
              <mat-label>Commune</mat-label>

              <input type="text" matInput formControlName="infosEtranger" required>

              <mat-error *ngIf="adresseInfosEtranger.hasError('required')">
                <p>Ce champ est obligatoire</p>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </ng-template>

      <!-- Common complementary address info -->
      <div class="row">
        <div class="col-10">
          <!--Complément-->
          <mat-form-field appearance="outline">
            <mat-label>Complément</mat-label>

            <input type="text" matInput formControlName="complement">
          </mat-form-field>
        </div>


        <div class="col-2">
          <!--Boîte Postale-->
          <mat-form-field appearance="outline">
            <mat-label>Boîte Postale</mat-label>

            <input type="text" matInput formControlName="bp">

            <mat-error *ngIf="adresseBp.hasError('maxlength')">
              <p>Taille maximale : 250 caractères</p>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
