import { ValidatorFn, AbstractControl } from '@angular/forms';

/** Le champ doit respecter le format d'un numéro de demande Public : <AAAA><MM><compteur sur 6 position><Pp> */
export function isNumeroDemandePublic(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const masqueDemande = /^20\d{2}(0[1-9]|1[0-2])\d{6}[Pp]$/.test(control.value);
    return masqueDemande ? null : { 'isNumeroDemandePublic': true };
  };
}

/** Le champ doit respecter le format d'un numéro de demande : <AAAA><MM><compteur sur 6 position><P,p, A,a, i ou I> */
export function isNumeroDemande(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const masqueDemande = /^20\d{2}(0[1-9]|1[0-2])\d{6}[PAIpai]$/.test(control.value);
    return masqueDemande ? null : { 'isNumeroDemande': { value: control.value } };
  };
}

/** Le champ doit respecter le format d'un numéro de demande : <AAAA><MM><compteur sur 6 position><A, a, i, I> */
export function isNumeroDemandeIntra(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const masqueDemande = /^20\d{2}(0[1-9]|1[0-2])\d{6}[AIai]$/.test(control.value);
    return masqueDemande ? null : { 'isNumeroDemandeIntra': { value: control.value } };
  };
}
