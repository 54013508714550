import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILogo } from 'projects/sirc-public/src/app/shared/model/demand/logo.model';
import { environment } from 'projects/sirc-public/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LogoService {
  private readonly ENDPOINT = environment.hrefBase + '/api/logo';

  constructor(private readonly http: HttpClient) {}

  public getLogo(): Observable<Blob> {
    return this.http.get(this.ENDPOINT, { responseType: 'blob' });
  }

  public getAltText(): Observable<ILogo | null> {
    return this.http.get<ILogo>(this.ENDPOINT + '/alt-text');
  }

  public storeNewAltText(altText: string): Observable<void> {
    return this.http.post<void>(this.ENDPOINT + '/alt-text', { text: altText });
  }

  public upload(file: File): Observable<void> {
    const data = new FormData();
    data.append('file', file);

    return this.http.post<void>(this.ENDPOINT, data);
  }
}
