import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbState, Demande, DemandeSharedService, ModeIndemnite } from 'sirc-lib';
import { DemandeFormBuilder } from '../../form/builder/demande-form.builder';
import { BreadcrumbSharedService } from '../../services/breadcrumb.service';
import { TitleService, TitleState } from '../../shared/service/title.service';



@Component({
  selector: 'app-simulateur-indemnite',
  templateUrl: './simulateur-indemnite.component.html',

})
export class SimulateurIndemniteComponent implements OnInit {
  public readonly ModeIndeminte = ModeIndemnite;

  // Formulaire
  public modeleForm: FormGroup;

  constructor(
    private readonly titleService: TitleService,
    private readonly formBuilder: FormBuilder,
    private readonly demandeSharedService: DemandeSharedService,
    @Inject('BreadcrumbSharedService')
    private readonly breadcrumbSharedService: BreadcrumbSharedService,
  ) { }

  public ngOnInit(): void {
    // Set form to indeminte step
    this.demandeSharedService.demande = new Demande({});
    this.demandeSharedService.form = new DemandeFormBuilder(this.formBuilder, new Demande({}), false).build();

    this.breadcrumbSharedService.setState(BreadcrumbState.SIMULATOR_INDEMNITY);

    this.titleService.changeTitle(TitleState.SIMULATOR_INDEMNITY);
  }
}
