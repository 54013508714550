import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Article } from '../../../models/article.model';
import { AbstractBreadcrumbService, BreadcrumbState } from '../../../services/provided/abstract-breadcrumb.service';
import { LibTitleService, LibTitleState } from '../../../services/provided/lib-title.service';

@Component({
  selector: 'lib-multiple-articles',
  templateUrl: './multiple-articles.component.html',
  styleUrls: ['./multiple-articles.component.scss']
})
export class MultipleArticlesComponent implements OnInit {
  public titleState: LibTitleState;
  public title: string;
  public articles: Article[];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sanitizer: DomSanitizer,
    @Inject('TitleService')
    private readonly titleService: LibTitleService,
    @Inject('BreadcrumbSharedService')
    private readonly breadcrumbSharedService: AbstractBreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.titleState = this.route.snapshot.data.titleState;
    this.title = this.route.snapshot.data.title;
    this.articles = this.route.snapshot.data.articles;

    this.articles.sort((a1, a2) => a1.ordre - a2.ordre);

    this.articles.forEach((article) => article.safeContenu = this.sanitizer.bypassSecurityTrustHtml(article.contenu));

    this.breadcrumbSharedService.setState(BreadcrumbState.HELP_LIBRARY, this.title);

    if (this.titleState) {
      this.titleService.changeTitle(this.titleState);
    }
  }

  public scroll(): void {
    document.querySelector('mat-expansion-panel.mat-expanded')?.scrollIntoView({
      block: 'start',
      inline: 'start',
      behavior: 'smooth'
    });
  }
}
