import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { Adresse } from '../../models/adresse.model';
import { HttpAdresseService } from '../../services/http/http-adresse.service';


@Component({
  selector: 'lib-autocomplete-adresse-fr',
  templateUrl: './autocomplete-adresse-fr.component.html',
  styleUrls: ['./autocomplete-adresse-fr.component.scss'],
})
export class AutocompleteAdresseFrComponent implements OnInit, OnDestroy {
  @Input()
  public delay = 500;

  @Input()
  public formAdresse: FormGroup;

  public minSize;

  private sub: Subscription;
  private selectedAdresse = false; // mark address selected from the option
  private queryChanged = false; // mark query value as changed

  public responseAdresse: Observable<Adresse[]>;
  public formQuery: FormGroup;

  public apiAdresseAvailable = true;
  public loadingAutocomplete = false;
  public isManualAdress = false;

  constructor(
    private readonly adresseService: HttpAdresseService,
    private readonly formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    // Fetch config
    this.adresseService.fetchMinSize().subscribe((minSize) => this.minSize = minSize);

    this.formQuery = this.formBuilder.group({
      query: this.formBuilder.control(this.existAdresse() ? this.formAdresse.getRawValue() : '', [Validators.required]),
    });

    this.sub = this.formAdresse.valueChanges.subscribe(() => {
      // address changes by calling siren, patch this address to query and mark as value not changed
      this.formControlQuery.setValue(this.formAdresse.getRawValue());
      this.queryChanged = false;
    });

    // Based status to adresse ctrl because in client pays not disable thus adresse FormGroup not disable but adresse ctrl disable
    this.sub.add(this.formAdresse.get('voie').statusChanges.subscribe((status) => {
      if ('DISABLED' === status) {
        this.formQuery.disable();
      } else if ('INVALID' === status) {
        this.formQuery.enable();
      }
    }));

    const statusInit = this.formAdresse.get('voie').status;
    if ('DISABLED' === statusInit) {
      this.formQuery.disable();
    } else if ('INVALID' === statusInit) {
      this.formQuery.enable();
    }

    this.responseAdresse = this.formControlQuery.valueChanges.pipe(
      tap(() => this.queryChanged = true),
      filter((query: string) => query && query.length >= this.minSize),
      tap(() => this.selectedAdresse = false),
      debounceTime(this.delay),
      tap(() => this.loadingAutocomplete = true),
      map((value: string) => this.adresseService.autocomplete(value).pipe(
        finalize(() => this.loadingAutocomplete = false),
        catchError(() => {
          this.apiAdresseAvailable = false;
          return EMPTY;
        }),
      )),
      mergeMap((adresse) => adresse),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public get formControlQuery(): FormControl {
    return this.formQuery.get('query') as FormControl;
  }

  public displayAdresse(adresse: Adresse): string {
    if (adresse && adresse.localisation && adresse.localisation.codePostal && adresse.localisation.commune) {
      return `${adresse.voie}, ${adresse.localisation.codePostal} - ${adresse.localisation.commune}`;
    }
    return '';
  }

  public selectAdresse(event: MatAutocompleteSelectedEvent): void {
    // After selection, mark query value as non changed
    this.queryChanged = false;
    this.selectedAdresse = true;
    const selectedAdresse: Adresse = event.option.value;

    if (selectedAdresse) {
      // pays subobject is preselected not override
      delete selectedAdresse.pays;

      this.formAdresse.patchValue({
        ...selectedAdresse,
      });

      // Force notify adresse value change
      this.formAdresse.updateValueAndValidity();
    }
  }

  /**
   * Check if the adresse is selected from the option
   */
  public validateAdresse(): void {
    if (!this.selectedAdresse && this.queryChanged) {
      this.formControlQuery.setValue(null);
    }
  }

  private existAdresse(): boolean {
    const adresse = this.formAdresse.getRawValue();

    return !!adresse && adresse.voie && adresse.localisation && adresse.localisation.codePostal && adresse.localisation.commune;
  }

  public get adresseLocalisation(): FormGroup {
    return this.formAdresse.get('localisation') as FormGroup;
  }

  public get adresseVoie(): FormGroup {
    return this.formAdresse.get('voie') as FormGroup;
  }

  public changeAdresseMode(): void {
    this.isManualAdress = !this.isManualAdress;
  }
}
