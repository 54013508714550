// CLASSES DTO "TRANSMISSION" (demande)
export class TransmissionDto {
  uuid: string;
  password: string;
  courrielEmployeur: string;
  courrielSalarie: string;
  dateSignature: Date;
  dateFinDelaiInstruction: Date;
  dateFinDelaiRetractation: Date;
  dateEnvisageeRupture: Date;

  public constructor({
    uuid = '',
    password = '',
    courrielEmployeur = '',
    courrielSalarie = '',
    dateSignature = null,
    dateFinDelaiInstruction = null,
    dateFinDelaiRetractation = null,
    dateEnvisageeRupture = null,
  }) {
    this.uuid = uuid;
    this.password = password;
    this.courrielEmployeur = courrielEmployeur;
    this.courrielSalarie = courrielSalarie;
    this.dateSignature = dateSignature;
    this.dateFinDelaiInstruction = dateFinDelaiInstruction;
    this.dateFinDelaiRetractation = dateFinDelaiRetractation;
    this.dateEnvisageeRupture = dateEnvisageeRupture;
  }
}
