import { AdresseFormBuilder } from './adresse-form.builder';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Employeur } from '../../models/employeur.model';
import { isNumeroSiret } from '../../validators/numero-siret.validators';

export class EmployeurFormBuilder {
  constructor(
    private fb: FormBuilder,
    private employeur: Employeur,
    private isDisabled: boolean,
  ) { }

  build(): FormGroup {

    return this.fb.group({
      id: this.fb.control({
        value: this.employeur.id,
        disabled: true,
      }),

      ape: this.fb.control({
        value: this.employeur.ape,
        disabled: this.isDisabled,
      }),

      courriel: this.fb.control({
        value: this.employeur.courriel,
        disabled: this.isDisabled,
      },
        [Validators.required, Validators.maxLength(250), Validators.email]
      ),

      effectif: this.fb.control({
        value: this.employeur.effectif,
        disabled: this.isDisabled,
      },
        [Validators.pattern('[0-9]{1,9}'), Validators.maxLength(10)]
      ),

      nomSignataire: this.fb.control({
        value: this.employeur.nomSignataire,
        disabled: this.isDisabled,
      },
        [Validators.maxLength(250)]
      ),

      raisonSociale: this.fb.control({
        value: this.employeur.raisonSociale,
        disabled: this.isDisabled,
      },
        [Validators.required, Validators.maxLength(250)]
      ),

      hasSiret: this.fb.control({
        value: !this.employeur.urssaf,
        disabled: this.isDisabled,
      }),

      siret: this.fb.control({
        value: this.employeur.siret,
        disabled: this.employeur.urssaf ? true : this.isDisabled,
      },
        [
          Validators.required,
          Validators.maxLength(14),
          Validators.minLength(14),
          Validators.pattern(/([0-9]){14}/),
          isNumeroSiret(),
        ]
      ),

      telephone: this.fb.control({
        value: this.employeur.telephone,
        disabled: this.isDisabled,
      },
        [
          Validators.maxLength(20),
          Validators.pattern(/(\+)*([0-9])+/),
        ]
      ),

      urssaf: this.fb.control({
        value: this.employeur.urssaf,
        disabled: !this.employeur.urssaf ? true : this.isDisabled,
      },
        [Validators.required, Validators.maxLength(250)]
      ),

      adresse: new AdresseFormBuilder(this.fb, this.employeur.adresse, this.isDisabled).build(),

      hasCorrespondance: this.fb.control({
        value: this.employeur.adresseCorrespondance.id !== null,
        disabled: this.isDisabled,
      }),

      adresseCorrespondance: new AdresseFormBuilder(
        this.fb,
        this.employeur.adresseCorrespondance,
        this.employeur.adresseCorrespondance.id === null ? true : this.isDisabled
      ).build(),

    });
  }
}
