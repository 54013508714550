<div class="box">
  <mat-card>
    <mat-card-header class="mb-2">
      <mat-card-title>
        <h2>{{ newDemand ? 'Définition' : 'Modification' }} du mot de passe</h2>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <p class="hint-form">* les champs sont obligatoires</p>
      <form class="password-definition-form d-flex flex-column" autocomplete="off" [formGroup]="form" (ngSubmit)="submit()">
        <div class="d-flex">
          <div class="d-flex flex-column w-50">
            <mat-form-field appearance="outline">
              <mat-label>
                Mot de passe
                <span class="sr-only">Doit contenir au moins 8 caractères. Doit contenir au moins une lettre majuscule. Doit contenir au moins une lettre minuscule.
                Doit contenir au moins un chiffre. Doit contenir au moins un caractère spécial.</span>
              </mat-label>
              <input [type]="showPassword ? 'text' : 'password'" matInput formControlName="password" [errorStateMatcher]="matcher" required>

              <button matSuffix class="mat-icon-input" (click)="showPassword = !showPassword" type="button"
                aria-pressed="false" [attr.aria-label]="(showPassword ? 'Masquer' : 'Afficher') + ' le mot de passe'">
                <mat-icon aria-hidden="true">{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
              </button>

              <mat-error *ngIf="passwordCtrl.hasError('required')">
                <p>Ce champ est obligatoire</p>
              </mat-error>

              <mat-error *ngIf="passwordCtrl.hasError('strong')">
                <p>La complexité du mot de passe n'est pas suffisante.</p>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="mb-1">
              <mat-label>Confirmation mot de passe</mat-label>
              <input [type]="showConfirmPassword ? 'text' : 'password'" matInput formControlName="confirmPassword"
                [errorStateMatcher]="matcher" required>

              <button matSuffix class="mat-icon-input" (click)="showConfirmPassword = !showConfirmPassword" type="button"
                aria-pressed="false"
                [attr.aria-label]="(showConfirmPassword ? 'Masquer' : 'Afficher') + ' la confirmation du mot de passe' ">
                <mat-icon aria-hidden="true">{{ showConfirmPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
              </button>

              <mat-error *ngIf="confirmPasswordCtrl.hasError('required')">
                <p>Ce champ est obligatoire</p>
              </mat-error>
              <mat-error *ngIf="confirmPasswordCtrl.hasError('noPasswordMatch')">
                <p>Les mots de passe saisis ne correspondent pas</p>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="password-requirements-pointer" aria-hidden="true"></div>
          <div class="password-requirements p-1" aria-hidden="true">
           <app-password-strength [password]="passwordCtrl.value"></app-password-strength>
          </div>
        </div>


        <div class="d-flex mb-1">
          <button mat-button class="mat-primary" [libDisabled]="!form.valid || isSubmitting" type="submit"
            aria-label="Enregistrer le mot de passe">
            Enregistrer
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
