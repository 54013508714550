import { CIVILITE } from '../constants/referentiel/civilite.enum';
import { Adresse } from './adresse.model';
import { ConventionCollective } from './convention-collective.model';
import { Qualification } from './qualification.model';

export class Salarie {
  id: number;
  civilite: CIVILITE;
  conventionCollective: ConventionCollective;
  courriel: string;
  dateNaissance: Date;
  emploi: string;
  nom: string;
  prenom: string;
  qualification: Qualification;
  telephone: string;
  adresse: Adresse;

  public constructor({
    id = null,
    civilite = CIVILITE.MADAME,
    conventionCollective = null,
    courriel = '',
    dateNaissance = null,
    emploi = '',
    nom = '',
    prenom = '',
    qualification = null,
    telephone = '',
    adresse = new Adresse({}),
  }) {
    this.id = id;
    this.civilite = civilite;
    this.conventionCollective = conventionCollective;
    this.courriel = courriel;
    this.dateNaissance = dateNaissance;
    this.emploi = emploi;
    this.nom = nom;
    this.prenom = prenom;
    this.qualification = qualification;
    this.telephone = telephone;
    this.adresse = adresse ? new Adresse(adresse) : new Adresse({});

  }
}
