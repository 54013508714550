import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LibTitleService, LibTitleState } from 'sirc-lib';

export class TitleState implements LibTitleState {
  static HOME: LibTitleState = { value: 'Accueil' };
  static DEMANDE_PASSWORD_UPDATE: LibTitleState = { value: 'Définition du mot de passe d\'une demande' };
  static DEMANDE_CREATE: LibTitleState = { value: 'Création d\'une demande' };
  static DEMANDE_UPDATE: LibTitleState = { value: 'Saisie d\'une demande' };
  static DEMANDE_TRANSMIT: LibTitleState = { value: 'Transmission d\'une demande' };
  static DEMANDE_FOLLOW: LibTitleState = { value: 'Suivi d\'une demande' };
  static SIMULATOR_DEADLINES: LibTitleState = { value: 'Simulation des délais de rétractation et d\'homologation' };
  static SIMULATOR_INDEMNITY: LibTitleState = { value: 'Simulation de l\'indemnité légale' };
  static STATIC_PAGE_RUPTURE: LibTitleState = { value: 'Information sur la rupture conventionnelle' };
  static STATIC_PAGE_INTERLOCUTOR: LibTitleState = { value: 'Mon interlocuteur en région' };
  static STATIC_PAGE_FAQ: LibTitleState = { value: 'Foire aux questions' };
  static STATIC_PAGE_OPERATING_INSTRUCTIONS: LibTitleState = { value: 'Visite guidée / Mode d\'emploi' };
  static STATIC_PAGE_LEGAL_INFORMATION: LibTitleState = { value: 'Accessibilité / Contacts / Mentions Légales' };

  static STEP_CALENDAR: LibTitleState = { value: ' - Étape calendrier' };
  static STEP_COMPENSATION: LibTitleState = { value: ' - Étape calcul de l\'indemnité' };
  static STEP_EMPLOYEE: LibTitleState = { value: ' - Étape salarié(e)' };
  static STEP_EMPLOYER: LibTitleState = { value: ' - Étape employeur' };
  static STEP_TRANSMISSION: LibTitleState = { value: ' - Étape transmission' };
  static STEP_VALIDATION: LibTitleState = { value: ' - Étape validation' };

  static SERVER_ERROR: LibTitleState = { value: 'Erreur serveur - ' };
  static INVALID_CREDENTIALS_ERROR: LibTitleState = { value: 'Identifiants invalides - ' };

  value: string;
}

@Injectable({ providedIn: 'root' })
export class TitleService implements LibTitleService {
  private readonly SUFFIX_TITLE = 'TéléRC';

  constructor(private readonly title: Title) { }

  public changeTitle(state: LibTitleState): void {
    this.title.setTitle(`${state.value} - ${this.SUFFIX_TITLE}`);
  }
}
