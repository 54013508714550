import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { MessageInfo } from 'sirc-lib';
import { environment } from 'projects/sirc-public/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MessageInfoService {
  private url = environment.hrefBase + `/api/parametre/message-info`;

  constructor(private readonly http: HttpClient) {}

  public getByCode(code: string): Observable<MessageInfo> {
    return this.http.get<MessageInfo>(`${this.url}/code/${code}`);
  }
}
