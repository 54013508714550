import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { BreadcrumbItem } from '../../utils/breadcrumb-item.model';


export enum BreadcrumbState {
  HOME,
  DEMANDE,

  // IntraRC
  LOGOUT,
  SEARCH,
  ORGANIZATION,
  ADD_ORGANIZATION,
  UPDATE_ORGANIZATION,
  USER_ACCOUNT,
  DAYS_OFF,
  REGIONS_DEPS,
  PARAMETERS,
  REINDEXATION,
  HELP_LIBRARY_ROOT,
  HELP_LIBRARY,
  ARTICLES,
  TABLEAUBORD,
  BATCHS,

  // TeleRC
  FOLLOW,
  SIMULATOR_DEADLINES,
  SIMULATOR_INDEMNITY,
}
export abstract class AbstractBreadcrumbService {
  public breadcrumbItems: Map<BreadcrumbState, BreadcrumbItem[]>;

  private _event: EventEmitter<BreadcrumbItem[]>;

  constructor() {
    this._event = new EventEmitter();
    this.initBreadcrumb();
  }

  public abstract initBreadcrumb(): void;

  public get observable(): Observable<BreadcrumbItem[]> {
    return this._event.asObservable();
  }

  /**
   * Get breadcrumb item list by state
   */
  public getBreadCrumbItems(state: BreadcrumbState): BreadcrumbItem[] {
    return this.breadcrumbItems.get(state);
  }

  /**
   * Display static breadcrumb
   *
   * @param state The state of breadcrumb
   * @param contextualName the dynamic item name
   * @param contextualUrl the dynamic item url
   */
  public setState(state: BreadcrumbState, contextualName: string = null, contextualUrl: string = ''): void {
    const currentBreadcrumb = [...this.getBreadCrumbItems(state)];
    if (contextualName) {
      currentBreadcrumb.push(new BreadcrumbItem(contextualName, contextualUrl));
    }
    this._event.emit(currentBreadcrumb);
  }
}
