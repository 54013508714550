import { ErrorHandler, Injectable, NgZone} from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { TYPE_TOAST } from 'sirc-lib';
import { Router } from '@angular/router';
import { ToasterSharedService } from 'projects/sirc-lib/src/lib/services/shared/toaster-shared.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TokenExpiredDialogComponent } from '../../shared/components/password-strength/dialog/token-expired-dialog.component';

@Injectable({ providedIn: 'root' })
export class SircErrorsHandler implements ErrorHandler {

  constructor(
    private readonly ngZone: NgZone,
    private readonly router: Router,
    private readonly toasterService: ToasterSharedService,
    private dialog: MatDialog
  ) {}

  handleError(error: Error): void {
    // NgZone : nécessaire pour le bon fonctionnement du ToasterService
    this.ngZone.run(() => {
      let messageErreur = 'Une erreur est survenue.';
      let titreMessageErreur = 'Une erreur est survenue';

      // Erreur Serveur/connexion
      if (error instanceof HttpErrorResponse) {
        if (!navigator.onLine) { // Erreur hors-ligne
          messageErreur = 'Aucune connection Internet';
        } else {
          switch (error.status) {
            case HttpStatusCode.Unauthorized :
              messageErreur = 'Votre ticket est expiré veuillez vous reconnecter à une demande.';
              this.showTokenExpiredDialog();
              break;
            case HttpStatusCode.NotFound :
              messageErreur = 'La ressource demandée n\'a pas été trouvée.';
              break;
            case HttpStatusCode.NotAcceptable :
              messageErreur = `Une erreur est survenue. ${error.error.message}.`;
              break;
            case HttpStatusCode.PreconditionFailed :
              titreMessageErreur = 'Votre saisie n\'est pas valide';
              messageErreur = error.error.message;
              break;
            case HttpStatusCode.PayloadTooLarge :
              titreMessageErreur = 'Document trop lourd';
              messageErreur = 'Le poids du document que vous essayez de déposer semble dépasser la limite maximale autorisée (10Mo)';
              break;
            case HttpStatusCode.UnprocessableEntity :
              messageErreur = 'Une pièce-jointe est infectée par un virus. Demande annulée.';
              break;
            case HttpStatusCode.InternalServerError :
              messageErreur = `Une erreur est survenue. ${error.error.message}`;
              break;
            default :
              messageErreur = 'Une erreur inconnue est survenue.';
          }
          titreMessageErreur += ` (${error.status})`;

          console.error(`ERREUR SERVEUR, code ${error.status} - ${error.message} - ${error.error}`);
        }
      } else {
        console.error('Une erreur Client est survenue:', error.message);
      }

      console.error(error);

      this.toasterService.create({
        type: TYPE_TOAST.ERREUR,
        title: titreMessageErreur,
        body: messageErreur,
      });
    });
  }

  private showTokenExpiredDialog(): void {
    const dialogRef: MatDialogRef<TokenExpiredDialogComponent> = this.dialog.open(TokenExpiredDialogComponent, {
      width: '250px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe( result => {
      this.router.navigate(['/accueil']);
    });
  }
}
