import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnInit, OnChanges {
  private static readonly MIN_LENGTH = 8;
  private static readonly REGEX_UPPER_CASE = /[A-Z]/;
  private static readonly REGEX_LOWER_CASE = /[a-z]/;
  private static readonly REGEX_NUMBER = /\d/;
  private static readonly REGEX_SPECIAL_CHAR = /[\/\\!@#$%£€*§+=-]/;

  @Input() public password: string;

  public init: boolean;
  public hasEightCharacters!: boolean;
  public hasUpper!: boolean;
  public hasLower!: boolean;
  public hasNumber!: boolean;
  public hasSpecialChar!: boolean;

  public ngOnInit(): void {
    this.init = true;
  }

  public ngOnChanges(): void {
    this.init = false;
    this.checkStrength();
  }

  /**
   * Checks if given password has:
   * - At least 8 caracters
   * - At least one upper case letter
   * - At least one lower case letter
   * - At least one number
   * - At least one special char
   */
  private checkStrength(): void {
    this.hasEightCharacters = this.password.length >= PasswordStrengthComponent.MIN_LENGTH;
    this.hasUpper = PasswordStrengthComponent.REGEX_UPPER_CASE.test(this.password);
    this.hasLower = PasswordStrengthComponent.REGEX_LOWER_CASE.test(this.password);
    this.hasNumber = PasswordStrengthComponent.REGEX_NUMBER.test(this.password);
    this.hasSpecialChar = PasswordStrengthComponent.REGEX_SPECIAL_CHAR.test(this.password);
  }
}
