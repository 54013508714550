import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Parametre } from 'sirc-lib';
import { Observable } from 'rxjs';
import { environment } from 'projects/sirc-public/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ParametresService {
  private url = environment.hrefBase + `/api/parametre/parametre`;

  constructor(private readonly http: HttpClient) {}

  public getByCode(code: string): Observable<Parametre> {
    return this.http.get<Parametre>(`${this.url}/code/${code}`);
  }
}
