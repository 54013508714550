import { Courrier } from './courrier.model';
import { Employeur } from './employeur.model';
import { Entretien } from './entretien.model';
import { Etape } from './etape.model';
import { MotifDecision } from './motif-decision.model';
import { OrganismeMiniDto } from './organisme-mini-dto.dto';
import { Remuneration } from './remuneration.model';
import { Salarie } from './salarie.model';
import { Statut } from './statut.model';
import { Transmission } from './transmission.model';
import {CompteUtilisateur} from '../../../../sirc-intra/src/app/modeles/compte-utilisateur.model';
import {Suivi} from "./suivi.model";
import { Decision } from './decision-history.model';

export class Demande {
  id: number;
  uuid: string;
  numero: string;
  courrielContact: string;

  autresClauses: string;
  commentaire: string;

  dateCreation: Date;
  dateEnvisageeRupture: Date;
  dateFinDelaiInstruction: Date;
  dateFinDelaiRetractation: Date;
  dateImpression: Date;
  dateSignature: Date;
  dateTeletransmission: Date;
  documentFormulaireDemande: string;
  remarqueEntretiens: string;
  employeur: Employeur;
  organismeReferent: OrganismeMiniDto;
  remuneration: Remuneration;
  salarie: Salarie;
  entretiens: Entretien[];
  transmission: Transmission;

  // For intraRC
  authorized?: boolean;
  dateCloture?: Date;
  dateDecision?: Date;
  dateDecisionImpression?: Date;
  dateReception?: Date;
  dateTransfert?: Date;
  decisionMotifAutre?: string;
  etape?: Etape;
  statut?: Statut;
  courriers?: Courrier[];
  historiqueDecisions?: Decision[];
  organismeAttribution?: OrganismeMiniDto;
  organismeReception?: OrganismeMiniDto;
  motifsDecision?: MotifDecision[];
  retriever?: string;
  isRetrieved?: boolean;
  suivi?: Suivi;

  constructor({
    id = null,
    uuid = '',
    numero = '',
    courrielContact = '',
    autresClauses = '',
    commentaire = '',

    dateCreation = null,
    dateEnvisageeRupture = null,
    dateFinDelaiInstruction = null,
    dateFinDelaiRetractation = null,
    dateImpression = null,
    dateSignature = null,
    dateTeletransmission = null,
    documentFormulaireDemande = '',
    remarqueEntretiens = '',
    employeur = new Employeur({}),
    organismeReferent = null,
    remuneration = new Remuneration({}),
    salarie = new Salarie({}),
    entretiens = [] as Entretien[],
    transmission = new Transmission({}),

    authorized = false,
    dateCloture = null,
    dateDecision = null,
    dateDecisionImpression = null,
    dateReception = null,
    dateTransfert = null,
    decisionMotifAutre = '',
    etape = null,
    statut = null,
    courriers = [],
    historiqueDecisions = [],
    organismeAttribution = null,
    organismeReception = null,
    motifsDecision = [],
    isRetrieved = false,
    retriever = null,
    suivi = null,

  }) {
    this.id = id;
    this.uuid = uuid;
    this.numero = numero;
    this.courrielContact = courrielContact;
    this.autresClauses = autresClauses;
    this.commentaire = commentaire;
    this.dateCreation = dateCreation;
    this.dateEnvisageeRupture = dateEnvisageeRupture;
    this.dateFinDelaiInstruction = dateFinDelaiInstruction;
    this.dateFinDelaiRetractation = dateFinDelaiRetractation;
    this.dateImpression = dateImpression;
    this.dateSignature = dateSignature;
    this.dateTeletransmission = dateTeletransmission;
    this.documentFormulaireDemande = documentFormulaireDemande;
    this.remarqueEntretiens = remarqueEntretiens;
    this.employeur = employeur ? new Employeur(employeur) : new Employeur({});
    this.organismeReferent = organismeReferent;
    this.remuneration = remuneration ? new Remuneration(remuneration) : new Remuneration({});
    this.salarie = salarie ? new Salarie(salarie) : new Salarie({});
    this.entretiens = entretiens && entretiens.length > 0 ? entretiens.map((entretien) => new Entretien(entretien)) : [new Entretien({})];
    this.transmission = transmission ? new Transmission(transmission) : new Transmission({});

    this.authorized = authorized;
    this.dateCloture = dateCloture;
    this.dateDecision = dateDecision;
    this.dateDecisionImpression = dateDecisionImpression;
    this.dateReception = dateReception;
    this.dateTransfert = dateTransfert;
    this.decisionMotifAutre = decisionMotifAutre;
    this.etape = etape;
    this.statut = statut;
    this.courriers = courriers;
    this.historiqueDecisions = historiqueDecisions;
    this.organismeAttribution = organismeAttribution;
    this.organismeReception = organismeReception;
    this.motifsDecision = motifsDecision;
    this.isRetrieved = isRetrieved;
    this.retriever = retriever;
    this.suivi = suivi;
  }
}
