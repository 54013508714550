import { QualiteAssistant } from './qualite-assistant.model';

export class Entretien {
  id: number;
  date: Date;
  employeurAssiste: boolean;
  nomAssistantEmployeur: string;
  nomAssistantSalarie: string;
  qualiteAssistantEmployeur: QualiteAssistant;
  qualiteAssistantSalarie: QualiteAssistant;
  salarieAssiste: boolean;

  public constructor({
    id = null,
    date = null,
    employeurAssiste = false,
    nomAssistantEmployeur = '',
    nomAssistantSalarie = '',
    qualiteAssistantEmployeur = null,
    qualiteAssistantSalarie = null,
    salarieAssiste = false,
  }) {
    this.id = id;
    this.date = date;
    this.employeurAssiste = employeurAssiste;
    this.nomAssistantEmployeur = nomAssistantEmployeur;
    this.nomAssistantSalarie = nomAssistantSalarie;
    this.qualiteAssistantEmployeur = qualiteAssistantEmployeur;
    this.qualiteAssistantSalarie = qualiteAssistantSalarie;
    this.salarieAssiste = salarieAssiste;
  }
}
