import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { LibModule } from 'sirc-lib';
import { AddressPipe } from '../pipes/address.pipe';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';

@NgModule({
  declarations: [
    AddressPipe,
    PasswordStrengthComponent,
  ],
  imports: [
    LibModule,
    MatTableModule,
  ],
  exports: [
    LibModule,
    MatTableModule,
    AddressPipe,
    PasswordStrengthComponent,
  ]
})
export class SharedModule { }
