import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Pays } from '../../models/pays.model';
import { DemandeSharedService } from '../../services/shared/demande-shared.service';

@Component({
  selector: 'lib-adresse',
  templateUrl: './adresse.component.html',
  styleUrls: ['./adresse.component.scss']
})
export class AdresseComponent implements OnInit {

  @Input()
  public adresseForm: FormGroup;

  public listePays: Pays[];

  public selectCountry: boolean; // If country is selected

  constructor(
    private readonly demandeSharedService: DemandeSharedService,
  ) { }

  ngOnInit(): void {
    this.listePays = this.demandeSharedService.resolvedData.pays;

    this.setCountrySelected(this.adressePays.value);

    this.adresseForm.statusChanges.subscribe((status: string) => {
      if (status === 'DISABLED' && this.adressePays.value) {
        this.adresseForm.patchValue({
          pays: null,
          voie: null,
          complement: null,
          localisation: null,
          bp: null,
          infosEtranger: null,
        });
        this.adresseForm.markAsUntouched();
      }
    });
    this.adressePays.valueChanges.subscribe((pays) => this.setCountrySelected(pays));
  }

  /**
   * If country code exists, then enable address
   */
  setCountrySelected(pays: Pays): void {
    if (pays && pays.code) {
      this.selectCountry = true;
      if (!this.adressePays.disabled) { // If it's not in readonly mode
        if (pays.code === 'FR') {
          this.adresseLocalisation.enable();
          this.adresseInfosEtranger.disable();
        } else {
          this.adresseLocalisation.disable();
          this.adresseInfosEtranger.enable();
        }
      }
    } else {
      this.selectCountry = false;
    }
  }

  /** Changement du select pays => Si étranger alors vider les champs de l'adresse */
  adressePaysChange(): void {
    this.selectCountry = true;
    this.adresseVoie.reset();
    this.adresseComplement.reset();
    this.adresseLocalisation.reset();
    this.adresseInfosEtranger.reset();
    this.adresseBp.reset();
  }

  public compareFunction(o1: any, o2: any): boolean {
    return o1 && o2 && o1.id === o2.id;
  }

  public get adresseVoie(): FormControl {
    return this.adresseForm.get('voie') as FormControl;
  }

  public get adresseComplement(): FormControl {
    return this.adresseForm.get('complement') as FormControl;
  }

  public get adresseLocalisation(): FormControl {
    return this.adresseForm.get('localisation') as FormControl;
  }

  public get adresseBp(): FormControl {
    return this.adresseForm.get('bp') as FormControl;
  }

  public get adressePays(): FormControl {
    return this.adresseForm.get('pays') as FormControl;
  }

  public get adresseInfosEtranger(): FormControl {
    return this.adresseForm.get('infosEtranger') as FormControl;
  }

  public get isCountryFrance(): boolean {
    return this.adressePays.value ? (this.adressePays.value as Pays).code === 'FR' : false;
  }

}
