import { ValidatorFn, AbstractControl } from '@angular/forms';

/** La chaine de caractères doit êtes non-nulle, non vide, et ne peut pas être composée seulement d'espaces. */
export function isNotBlank(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (!control.value) {
        return null;
      }
      const isBlank = (control.value.trim().length === 0);
      return isBlank ? { 'isNotBlank': true } : null;
    };
}
