import { ValidatorFn, AbstractControl } from '@angular/forms';

/** Le champ doit respecter le format d'un code SIRET (clé de luhn) */
export function isNumeroSiret(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    // In value not exit return null
    if (!control.value) {
      return null;
    }

    const siret = control.value;

    // If size less than 14 return error
    if (siret.length < 14) {
      return { isNumeroSiret: true };
    }

    // Check if siret of 'La Poste'
    const isPostSiret = siret.length === 14 && (siret.substring(0, 9) === '356000000');

    // Compute siret checksum
    const checksum = Array.from(siret)
          .reverse()
          .map((char, index) => {
              let digit = Number(char);
              digit = index % 2 === 1 ? digit * 2 : digit;
              digit = digit > 9 ? digit - 9 : digit;
              return digit;
          })
          .reduce((previous, current) => previous + current);

    // If luhn checksum ok or siret of 'La Poste' return null
    if (checksum % 10 === 0 || isPostSiret) {
      return null;
    } else {
      return { isNumeroSiret: true };
    }
  };
}
