export enum DemandeEtape {
  EMPLOYEUR = 'employeur',
  SALARIE = 'salarie',
  INDEMNITE = 'remuneration',
  CALENDRIER = 'calendrier',
  VALIDATION = 'validation',
  TRANSMISSION = 'transmission',
  CONTROLE = 'controle',
  DECISION = 'decision',
  GESTION = 'gestion',
  COURRIER = 'courriers',
}
