import { Injectable } from '@angular/core';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import { TYPE_TOAST } from '../../constants/type-toast.enum';

export class SircToast {
  public type: TYPE_TOAST;
  public title?: string;
  public timeout?: number;
  public body: any;
  public bodyOutputType?: BodyOutputType;

  public constructor(
    type,
    title = null as string,
    body,
    bodyOutputType = BodyOutputType.TrustedHtml as BodyOutputType,
    timeout = null as number,
  ) {
    this.type = type;
    this.title = title;
    this.body = body;
    this.bodyOutputType = bodyOutputType;
    this.timeout = timeout;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ToasterSharedService {
  public constructor(private readonly toasterService: ToasterService) {}

  public create(data: SircToast): Toast {
    const toast = { ...data };
    toast.body = `<p>${data.body}</p>`;
    toast.bodyOutputType = BodyOutputType.TrustedHtml;
    return this.toasterService.pop(toast);
  }

  public clear(toastId: string): void {
    this.toasterService.clear(toastId);
  }
}
