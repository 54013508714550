<h2 class="title">{{ title }}</h2>

<mat-accordion>
  <mat-expansion-panel *ngFor="let article of articles" (click)="scroll()">
    <mat-expansion-panel-header>
      <mat-panel-title class="bold">
        <h3>{{ article.titre }}</h3>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div [innerHTML]="article.safeContenu"></div>
  </mat-expansion-panel>
</mat-accordion>
