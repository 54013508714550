import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbState, MessageInfo } from 'sirc-lib';
import { BreadcrumbSharedService } from '../../services/breadcrumb.service';
import { TitleService, TitleState } from '../../shared/service/title.service';
import { DialogEditDemandComponent } from '../commun/dialog-edit-demand/dialog-edit-demand.component';
import { DialogCreateDemandComponent } from './../commun/dialog-create-demand/dialog-create-demand.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public urlPdfSalProtect: string;
  public messageInfo: MessageInfo;


  constructor(
    private readonly titleService: TitleService,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    @Inject('BreadcrumbSharedService')
    private readonly breadcrumbSharedService: BreadcrumbSharedService
  ) { }

  ngOnInit(): void {
    this.urlPdfSalProtect = this.route.snapshot.data.parametre[0].valeur;
    this.messageInfo = this.route.snapshot.data.parametre[1];
    this.breadcrumbSharedService.setState(BreadcrumbState.HOME);

    this.titleService.changeTitle(TitleState.HOME);
  }

  /**
   * Create a new demand
   */
  createDemand(): void {
    this.titleService.changeTitle(TitleState.DEMANDE_CREATE);

    this.dialog.open(DialogCreateDemandComponent, {
      data: {
        pdfSalarieProtegeLien: this.urlPdfSalProtect,
      },
    }).afterClosed().subscribe((response) => {
      if (!response) {
        this.titleService.changeTitle(TitleState.HOME);
      }
    });
  }

  /**
   * Edit a demand
   */
  editDemand(openEdit: boolean): void {
    this.titleService.changeTitle(openEdit ? TitleState.DEMANDE_UPDATE : TitleState.DEMANDE_TRANSMIT);

    this.dialog.open(DialogEditDemandComponent, {
      data: {
        edit: openEdit,
      },
    }).afterClosed().subscribe((response) => {
      if (!response) {
        this.titleService.changeTitle(TitleState.HOME);
      }
    });
  }

}
