import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Article } from 'sirc-lib';
import { LibArticleService } from '../services/provided/lib-article.service';

@Injectable({
  providedIn: 'root',
})
export class ArticleResolver implements Resolve<Article[]> {
  constructor(
    @Inject('LibArticleService') private articleService: LibArticleService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Article[]> {
    return this.articleService.getByType(route.data.type);
  }
}
