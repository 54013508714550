export enum CODE_PARAMETRES {
    CODE_P_LOGO_MARIANNE                    = 'LOGO_MARIANNE',
    CODE_P_NOM_MINISTERE                    = 'NOM_MINISTERE',
    CODE_P_INFOS_MINISTERE                  = 'INFOS_MINISTERE',
    CODE_P_URL_TELERC                       = 'URL_TELERC',
    CODE_P_URL_INTRARC                      = 'URL_INTRARC',
    CODE_P_MESSAGE_INFO_RAPPORT             = 'MESSAGE_INFO_RAPPORT',
    CODE_P_LIEN_PORTAIL_DECISIONNEL         = 'LIEN_PORTAIL_DECISIONNEL',
    CODE_P_CODES_INSEE_DPT_59L              = 'CODES_INSEE_DPT_59L',
    CODE_P_CODES_INSEE_DPT_59V              = 'CODES_INSEE_DPT_59V',
    CODE_P_BATCH_SUPPR_INTRA_NBR_DEMANDES   = 'BATCH_SUPPR_INTRA_NBR_DEMANDES',
    CODE_P_LIEN_PDF_SALARIE_PROTEGE         = 'LIEN_PDF_SALARIE_PROTEGE',
}









