import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Entretien } from '../../models/entretien.model';

export class EntretienFormBuilder {
  constructor(
    private fb: FormBuilder,
    private entretien: Entretien,
    private isDisabled: boolean,
  ) { }

  build(): FormGroup {
    return this.fb.group({
      id: this.fb.control({
        value: this.entretien.id,
        disabled: true,
      }),

      date: this.fb.control(
        {
          value: this.entretien.date,
          disabled: this.isDisabled,
        },
        [Validators.required]
      ),
      employeurAssiste: this.fb.control(
        {
          value: this.entretien.employeurAssiste,
          disabled: this.isDisabled,
        },
        [Validators.required]
      ),
      salarieAssiste: this.fb.control(
        {
          value: this.entretien.salarieAssiste,
          disabled: this.isDisabled,
        },
        [Validators.required]
      ),
      nomAssistantEmployeur: this.fb.control(
        {
          value: this.entretien.nomAssistantEmployeur,
          disabled: this.entretien.employeurAssiste ? this.isDisabled : true,
        },
        [
          Validators.required, Validators.maxLength(250)
        ]
      ),
      nomAssistantSalarie: this.fb.control(
        {
          value: this.entretien.nomAssistantSalarie,
          disabled: this.entretien.salarieAssiste ? this.isDisabled : true,
        },
        [
          Validators.required, Validators.maxLength(250)
        ]
      ),
      qualiteAssistantEmployeur: this.fb.control(
        {
          value: this.entretien.qualiteAssistantEmployeur,
          disabled: this.entretien.employeurAssiste ? this.isDisabled : true,
        },
        [Validators.required]
      ),
      qualiteAssistantSalarie: this.fb.control(
        {
          value: this.entretien.qualiteAssistantSalarie,
          disabled: this.entretien.salarieAssiste ? this.isDisabled : true,
        },
        [Validators.required]
      ),
    },
      {
        validators: AssisteValidator
      }
    );
  }
}

/**
 * The employer can only be assisted if the employee has chosen to be
 */
export function AssisteValidator(group: AbstractControl): ValidationErrors | null {
  const isEmployeurAssiste = group.get('employeurAssiste').value;
  const isSalarieAssiste = group.get('salarieAssiste').value;

  if (isEmployeurAssiste && !isSalarieAssiste) {
    return { isAssisteEligible: true };
  }

  return null;
}
