import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatesValidator, Demande } from 'sirc-lib';

export class TransmissionFormBuilder {
  constructor(
    private fb: FormBuilder,
    private demande: Demande,
    private isDisabled: boolean = false,
  ) { }

  build(): FormGroup {
    return this.fb.group({
      uuid: this.fb.control(this.demande.uuid),

      courrielContact: this.fb.control(this.demande.courrielContact),

      courrielEmployeur: this.fb.control({
        value: this.demande.employeur.courriel,
        disabled: this.isDisabled,
      },
        [Validators.required, Validators.maxLength(250), Validators.email]
      ),

      dateTeletransmission: this.fb.control(this.demande.dateTeletransmission, DatesValidator.isTransmissionDone),

      courrielSalarie: this.fb.control({
        value: this.demande.salarie.courriel,
        disabled: this.isDisabled,
      },
        [Validators.required, Validators.maxLength(250), Validators.email]
      ),

      entretiens: this.fb.control(this.demande.entretiens),

      dateSignature: this.fb.control({
        value: this.demande.dateSignature,
        disabled: this.isDisabled,
      },
        [Validators.required]
      ),

      dateFinDelaiRetractation: this.fb.control({
        value: this.demande.dateFinDelaiRetractation,
        disabled: this.isDisabled,
      },
        [Validators.required]
      ),

      dateFinDelaiInstruction: this.fb.control({
        value: this.demande.dateFinDelaiInstruction,
        disabled: this.isDisabled,
      },
        [Validators.required]
      ),

      dateEnvisageeRupture: this.fb.control({
        value: this.demande.dateEnvisageeRupture,
        disabled: this.isDisabled,
      },
        [Validators.required]
      )
    },
      {
        validators: [
          DatesValidator.isDateEnvisageeRuptureValide,
          DatesValidator.isDateSignaturePosterieurEntretien,
          DatesValidator.isTransmissionDone
        ]
      }
    );
  }
}
