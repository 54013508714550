<div class="breadcrumb-container d-flex" [ngClass]="{ 'empty': isEmpty }">
  <ul class="d-flex" role="navigation">
    <ng-container *ngFor="let item of items; let first = first; let last = last">
      <li *ngIf="item.url; else noNavigableItem">
        <mat-icon aria-hidden="true" *ngIf="!first">chevron_right</mat-icon>
        <a [routerLink]="item.url" [attr.aria-current]="last ? 'page' : null">{{ item.nom }}</a>
      </li>
      <ng-template #noNavigableItem>
        <li>
          <mat-icon aria-hidden="true" *ngIf="!first">chevron_right</mat-icon>
          <span [attr.aria-current]="last ? 'page' : null">{{ item.nom }}</span>
        </li>
      </ng-template>
    </ng-container>
  </ul>
</div>
