export class Transmission {
    id: number;
    uuid: string;
    password: string;
    courrielContact: string;
    courrielEmployeur: string;
    courrielSalarie: string;
    dateSignature: Date;
    dateFinDelaiInstruction: Date;
    dateFinDelaiRetractation: Date;
    dateEnvisageeRupture: Date;

    public constructor({
        id = null,
        uuid = '',
        password = '',
        courrielContact = '',
        courrielEmployeur = '',
        courrielSalarie = '',
        dateSignature = null,
        dateFinDelaiInstruction = null,
        dateFinDelaiRetractation = null,
        dateEnvisageeRupture = null
    }) {
        this.id = id;
        this.uuid = uuid;
        this.password = password;
        this.courrielContact = courrielContact;
        this.courrielEmployeur = courrielEmployeur;
        this.courrielSalarie = courrielSalarie;
        this.dateSignature = dateSignature;
        this.dateFinDelaiInstruction = dateFinDelaiInstruction;
        this.dateFinDelaiRetractation = dateFinDelaiRetractation;
        this.dateEnvisageeRupture = dateEnvisageeRupture;

    }

}
