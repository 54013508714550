import { Component, Input } from '@angular/core';
import { MESSAGE_IMPORTANCE } from '../../constants/referentiel/message-importance.enum';
import { MessageInfo } from '../../models/message-info.model';

@Component({
  selector: 'lib-message-info',
  templateUrl: './message-info.component.html'
})
export class MessageInfoComponent {

  @Input()
  public messageInfo: MessageInfo;

  constructor() { }

  /**
   * Show if the given message is visible and publication date is today or in the past
   */
  public get showMessageInfo(): boolean {
    return this.messageInfo && this.messageInfo.visible && this.messageInfo.datePublication
      && new Date(this.messageInfo.datePublication) <= new Date();
  }

  public get alertClass(): string {
    switch (this.messageInfo.importance) {
      case MESSAGE_IMPORTANCE.ALERTE:
        return 'alert-danger';
      case MESSAGE_IMPORTANCE.AVERTISSEMENT:
        return 'alert-warning';
      case MESSAGE_IMPORTANCE.INFO:
      default:
        return 'alert-info';
    }
  }
}
