import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CIVILITE } from '../../../constants/referentiel/civilite.enum';
import { ETAPE } from '../../../constants/referentiel/etape.enum';
import { ConventionCollective } from '../../../models/convention-collective.model';
import { Qualification } from '../../../models/qualification.model';
import { LibHttpDemandeService } from '../../../services/provided/lib-http-demande.service';
import { DemandeSharedService } from '../../../services/shared/demande-shared.service';

@Component({
  selector: 'lib-salarie',
  templateUrl: './salarie.component.html',
  styleUrls: ['./salarie.component.scss']
})
export class SalarieComponent implements OnInit {

  public readonly CIVILITE = CIVILITE;
  public readonly TODAY = new Date();

  public form: FormGroup;
  public parentForm: FormGroup;
  public listQualification: Qualification[];
  public listConventionCollective: ConventionCollective[];
  public filteredListConventionCollective: Observable<ConventionCollective[]>;
  public isOnBo: boolean;

  constructor(
    private demandeSharedService: DemandeSharedService,
    @Inject('LibHttpDemandeService')
    private demandeService: LibHttpDemandeService,
  ) { }

  ngOnInit(): void {
    this.isOnBo = this.demandeService.isOnBo();
    this.parentForm = this.demandeSharedService.form as FormGroup;
    this.form = this.parentForm.get('salarie') as FormGroup;
    this.listQualification = this.demandeSharedService.resolvedData.qualifications;
    this.listConventionCollective = this.demandeSharedService.resolvedData.conventionCollectives;
    this.filteredListConventionCollective = this.salarieConventionCollective.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );

    // Fix mat-radio compare with the object's reference, so always use the same object when initiate
    if (this.salarieQualification.value) {
      this.salarieQualification.setValue(
        this.listQualification.find(qualif => qualif.id === this.salarieQualification.value.id));
    }
  }

  /**
   * If demand is closed
   */
  public get isCloture(): boolean {
    if (this.isOnBo) {
      const etapeCtrl = this.parentForm.get('gestion').get('etape') as FormControl;
      return etapeCtrl && etapeCtrl.value && etapeCtrl.value.code === ETAPE.ETP_CLO_CODE;
    }
    return false;
  }

  /**
   * date range for date picker
   */
  public dateNaissFilter(date: Date): boolean {
    const momentDate = moment(date);
    const momentNow = moment();

    return momentDate.isBefore(momentNow, 'day') && momentDate.isAfter(momentNow.add(-100, 'year'));
  }

  public displayConventionCollective(conventionCollective: ConventionCollective): string {
    if (conventionCollective && conventionCollective.codeIdcc && conventionCollective.intitule) {
      return `${conventionCollective.codeIdcc} - ${conventionCollective.intitule}`;
    }
    return '';
  }

  private _filter(value: any): ConventionCollective[] {
    if (value?.codeIdcc) {
      const filterValue = value.codeIdcc;
      return this.listConventionCollective.filter(
        conventionCollective => conventionCollective.codeIdcc.toLowerCase().includes(filterValue)
      );
    } else if (value) {
      const filterValue = value.toLowerCase();
      return this.listConventionCollective.filter(conventionCollective =>
        conventionCollective.codeIdcc.toLowerCase().includes(filterValue)
        || conventionCollective.intitule.toLowerCase().includes(filterValue)
        || filterValue.includes(conventionCollective.codeIdcc.toLowerCase())
        || filterValue.includes(conventionCollective.intitule.toLowerCase())
      );
    }
    return this.listConventionCollective;
  }

  ///// FORMULAIRE - GETTERS
  public get salarieCivilite(): FormControl { return this.form.get('civilite') as FormControl; }
  public get salarieNom(): FormControl { return this.form.get('nom') as FormControl; }
  public get salariePrenom(): FormControl { return this.form.get('prenom') as FormControl; }
  public get salarieDateNaissance(): FormControl { return this.form.get('dateNaissance') as FormControl; }
  public get adresse(): FormGroup { return this.form.get('adresse') as FormGroup; }
  public get salarieTelephone(): FormControl { return this.form.get('telephone') as FormControl; }
  public get salarieCourriel(): FormControl { return this.form.get('courriel') as FormControl; }
  public get salarieQualification(): FormControl { return this.form.get('qualification') as FormControl; }
  public get salarieEmploi(): FormControl { return this.form.get('emploi') as FormControl; }
  public get salarieConventionCollective(): FormControl { return this.form.get('conventionCollective') as FormControl; }
}
