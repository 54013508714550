import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-etape-indicateur',
  template: `
    <ng-template [ngIf]="display">
      <mat-icon aria-hidden="true" *ngIf="valid; else error" class="ok">done</mat-icon>

      <ng-template #error>
        <mat-icon aria-hidden="true" class="ko" *ngIf="!isAdmin">close</mat-icon>
        <mat-icon aria-hidden="true" class="admin_ko" *ngIf="isAdmin">priority_high</mat-icon>
      </ng-template>
    </ng-template>
  `,
  styles: [`
    .ok { color: #000091; }
    .ko { color: #E1000F; }
    .admin_ko { color: #FF9575}
  `],
})
export class EtapeIndicateurComponent {

  @Input()
  public isAdmin = false;

  @Input()
  public display = true;

  @Input()
  public valid: boolean = null;

  constructor() { }

}
