// CLASSES DTO "REMUNERATION"
export class Remuneration {
  id: number;
  anciennete: number;
  indemnite: number;
  indemniteConventionnelle: number;
  indemniteLegale: number;
  moisDernierMois: number;
  anneeDernierMois: number;

  mois1Valeur: number;
  mois10Valeur: number;
  mois11Valeur: number;
  mois12Valeur: number;
  mois2Valeur: number;
  mois3Valeur: number;
  mois4Valeur: number;
  mois5Valeur: number;
  mois6Valeur: number;
  mois7Valeur: number;
  mois8Valeur: number;
  mois9Valeur: number;

  moyenneRemunerationMensuelle: number;
  prime: number;

  commentaire: string;

  public constructor({
    id = null,
    anciennete = null,
    indemnite = null,
    indemniteConventionnelle = null,
    indemniteLegale = null,
    moisDernierMois = null,
    anneeDernierMois = null,
    mois1Valeur = null,
    mois10Valeur = null,
    mois11Valeur = null,
    mois12Valeur = null,
    mois2Valeur = null,
    mois3Valeur = null,
    mois4Valeur = null,
    mois5Valeur = null,
    mois6Valeur = null,
    mois7Valeur = null,
    mois8Valeur = null,
    mois9Valeur = null,
    moyenneRemunerationMensuelle = null,
    prime = null,
    commentaire = '',
  }) {
    this.id = id;
    this.anciennete = anciennete;
    this.indemnite = indemnite;
    this.indemniteConventionnelle = indemniteConventionnelle;
    this.indemniteLegale = indemniteLegale;
    this.moisDernierMois = moisDernierMois;
    this.anneeDernierMois = anneeDernierMois;
    this.mois1Valeur = mois1Valeur;
    this.mois10Valeur = mois10Valeur;
    this.mois11Valeur = mois11Valeur;
    this.mois12Valeur = mois12Valeur;
    this.mois2Valeur = mois2Valeur;
    this.mois3Valeur = mois3Valeur;
    this.mois4Valeur = mois4Valeur;
    this.mois5Valeur = mois5Valeur;
    this.mois6Valeur = mois6Valeur;
    this.mois7Valeur = mois7Valeur;
    this.mois8Valeur = mois8Valeur;
    this.mois9Valeur = mois9Valeur;
    this.moyenneRemunerationMensuelle = moyenneRemunerationMensuelle;
    this.prime = prime;
    this.commentaire = commentaire;
  }

}
