import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { LoggedDemand } from "../../shared/model/demand/logged-demand.model";
import { SessionService } from "../../shared/service/session.service";
import { environment } from 'projects/sirc-public/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DemandeAuthenticationService {
  private readonly ENDPOINT_DEMAND_BASE = environment.hrefBase + '/api/demand';
  private readonly ENDPOINT_DEMAND_LOGIN = this.ENDPOINT_DEMAND_BASE + '/login';
  private readonly ENDPOINT_DEMAND_LOGIN_MAIL = this.ENDPOINT_DEMAND_BASE + '/login-mail';
  private readonly ENDPOINT_DEMAND_LOGGED = this.ENDPOINT_DEMAND_BASE + '/logged';
  private readonly ENDPOINT_DEMAND_LOGOUT = this.ENDPOINT_DEMAND_BASE + '/logout';

  constructor(
    private readonly http: HttpClient,
    private readonly sessionService: SessionService,
  ) {}

  /**
   * Log user with demand information
   * @param numero Numero demand
   * @param courriel Email used to create demand
   * @param password Password defined
   */
   public login(numero: string, courriel: string, password: string): Observable<LoggedDemand> {
    return this.http.post<LoggedDemand>(this.ENDPOINT_DEMAND_LOGIN, {
      numero,
      courriel,
      password,
    }).pipe(
      tap(logged => this.sessionService.token = logged.token),
    );
  }

  /**
   * Log user with demand information
   * @param uuid uuid demand
   * @param password Password defined
   */
   public loginMail(uuid: string, password: string): Observable<LoggedDemand> {
    return this.http.post<LoggedDemand>(this.ENDPOINT_DEMAND_LOGIN_MAIL, {
      uuid,
      password,
    }).pipe(
      tap(logged => this.sessionService.token = logged.token),
    );
  }

  /**
   * Chech if current session demand is logged (with cookie)
   */
  public logged(): Observable<void> {
    return this.http.get<string>(this.ENDPOINT_DEMAND_LOGGED).pipe(
      tap(token => this.sessionService.token = token),
      map(_ => void 0), // remove token to response don't use by another componant
    );
  }

  /**
   * Logout demand
   */
  public logout(): Observable<void> {
    return this.http.delete<void>(this.ENDPOINT_DEMAND_LOGOUT).pipe(
      tap(_ => this.sessionService.token = null),
    );
  }
}
