<form [formGroup]="formQuery">
  <!-- Code postal-->
  <mat-form-field appearance="outline" id="codePostal">
    <mat-label>Code postal</mat-label>
    <input type="text" matInput formControlName="codePostal" placeholder="(ex: 33520)" [matAutocomplete]="auto"
      aria-label="Code postal de l'adresse" (input)="startInput()" (focusout)="closeSelect()" required>
    <mat-progress-bar *ngIf="loadingAutocomplete" class="progress" mode="query"></mat-progress-bar>
    <mat-error *ngIf="codePostalControl.hasError('required')">
      <p>Ce champ est obligatoire</p>
    </mat-error>
  </mat-form-field>

  <!--Commune-->
  <mat-form-field appearance="outline" id="commune">
    <mat-label>Commune</mat-label>
    <input type="text" matInput formControlName="commune" [matAutocomplete]="auto" aria-label="Commune de l'adresse"
      (input)="startInput()" (focusout)="closeSelect()" required>
    <mat-progress-bar *ngIf="loadingAutocomplete" class="progress" mode="query"></mat-progress-bar>
    <mat-error *ngIf="communeControl.hasError('required')">
      <p>Ce champ est obligatoire</p>
    </mat-error>
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectVille($event)">
    <mat-option *ngFor="let ville of responseVille | async" class="option-ville" [value]="ville">
      <span>{{ ville.codePostal }} - {{ ville.commune }}</span>
    </mat-option>
  </mat-autocomplete>
</form>
