import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { LoggedDemand } from '../../shared/model/demand/logged-demand.model';
import { environment } from 'projects/sirc-public/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemporaryDemandService {
  private readonly ENDPOINT_TEMPORARY_DEMAND = environment.hrefBase + '/api/temporary-demand';

  constructor(
    private readonly http: HttpClient,
  ) {}

  /**
   *
   * @param uuid Check if temporary demand exist to uuid
   * @returns true if exist
   */
  public demandExist(uuid: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.ENDPOINT_TEMPORARY_DEMAND}/${uuid}`);
  }

  /**
   * Create temporary demand to valid email user
   * @param courriel email to create demand
   * @param recaptcha Captcha to valid not bot access
   * @returns true if created
   */
   public initCreationDemande(courriel: string, recaptcha: string): Observable<boolean> {
    return this.http.post<boolean>(this.ENDPOINT_TEMPORARY_DEMAND, {
      courriel,
      recaptcha,
    });
  }

  /**
   * Define password to temporary demand and create demand
   * @param uuid uuid of temporary demand
   * @param password password to demand
   * @returns Logged information to demand
   */
  public passwordDefinition(uuid: string, password: string): Observable<LoggedDemand> {
    return this.http.post<LoggedDemand>(`${this.ENDPOINT_TEMPORARY_DEMAND}/${uuid}`, { password });
  }
}
