import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Article, ArticleTypeEnum } from 'sirc-lib';
import { environment } from 'projects/sirc-public/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ArticleService {
  private readonly ENDPOINT_ARTICLE_TYPE = environment.hrefBase + '/api/article/type';

  constructor(
    private readonly http: HttpClient
  ) {}

  /**
   * Get list of articles by type
   */
  public getByType(type: ArticleTypeEnum): Observable<Article[]> {
    return this.http.get<Article[]>(`${this.ENDPOINT_ARTICLE_TYPE}/${type}`);
  }
}
