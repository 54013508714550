import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IToasterConfig, ToasterConfig } from 'angular2-toaster';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly focusableElement = 'button, a, input, select, textarea';

  public focus = false;

  // Configuration de base pour les Toasts
  public appConfig: IToasterConfig = new ToasterConfig({
    animation: 'fade', newestOnTop: true,
    positionClass: 'toast-top-right',
    toastContainerId: 1,
    timeout: { success: 6000, info: 6000, error: 0, warning: 6000 },
    showCloseButton: true,
    closeHtml:
      '<button type="button" aria-label="Fermer" title="Fermer la fenêtre d\'information" tabindex="0">x</button>',
    mouseoverTimerStop: true
  });

  private fragment: string;

  private subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.subscription = this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
      this.setFocusToElement();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setFocusToElement(): void {
    if (!this.fragment) {
      return;
    }

    try {
      const bloc = document.querySelector<HTMLElement>(`#${this.fragment}`);
      const focusable = bloc.querySelectorAll<HTMLElement>(this.focusableElement);

      if (focusable && focusable.length > 0) {
        focusable[0].focus();
      } else {
        bloc.focus();
      }
    } catch (e) { }
  }
}
