<form [formGroup]="form">
  <h3>Calendrier</h3>
  <section>
    <h4>Entretiens</h4>
    <div *ngFor="let entretien of entretiens.controls; let i = index;" formArrayName="entretiens"
      class="container-fluid">
      <fieldset [formGroupName]="i" [id]="'entretien-container-' + i">
        <legend [id]="'entretien-' + i">Entretien numéro {{ i + 1 }}</legend>
        <div [attr.aria-labelledby]="'entretien-' + i">
          <div class="row">
            <div class="col-4">
              <lib-date-form [form]="entretien" dateControlName="date" [isRequired]="true" label="Date de l'entretien"
                [enableHelp]="i === 0"
                tooltipText="Il est obligatoire que les parties procèdent au moins à un entretien préparatoire. La loi ne prévoit aucun formalisme particulier pour la tenue de ce ou de ces entretiens.">
              </lib-date-form>
            </div>

            <div class="delete-button-container">
              <button mat-button (click)="supprimerEntretien(i)" [attr.aria-label]="'Supprimer l\'entretien numéro ' + (i + 1)"
                [hidden]="i === 0 || form.disabled">
                <mat-icon aria-hidden="true">clear</mat-icon>
                Supprimer entretien
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <fieldset>
                <legend id="salarie-assiste" class="float-left mr-2">Salarié assisté :</legend>

                <mat-radio-group aria-labelledby="salarie-assiste" formControlName="salarieAssiste"
                  (change)="salarieAssisteChange(entretien, $event)">
                  <mat-radio-button class="mr-2" [value]="true">Oui</mat-radio-button>
                  <mat-radio-button [value]="false">Non</mat-radio-button>
                </mat-radio-group>
              </fieldset>

              <mat-error *ngIf="entretien.hasError('isAssisteEligible')">
                <p>Non respect des règles de l'assistance : l'employeur ne peut être assisté que si le (ou la) salarié(e) a
                  choisi de l'être</p>
              </mat-error>

              <fieldset *ngIf="isSalarieAssiste(entretien)" class="info">
                <legend>Information assistant</legend>

                <mat-form-field appearance="outline">
                  <mat-label id="nom-assistant">Nom et prénom de l'assistant</mat-label>
                  <input type="text" matInput formControlName="nomAssistantSalarie" aria-labelledby="nom-assistant">
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label id="qualite-salarie">Qualité</mat-label>
                  <mat-select formControlName="qualiteAssistantSalarie" [compareWith]="compareFunction"
                    aria-labelledby="qualite-salarie">
                    <mat-option *ngFor="let q of listeQualiteAssistantSalarie" [value]="q" title="{{q.intitule}}">
                      {{q.intitule}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </fieldset>

            </div>

            <div class="col-xs">
              <mat-divider vertical style="height:100%"></mat-divider>
            </div>

            <div class="col">
              <fieldset>
                <legend id="employeur-assiste" class="float-left mr-2">Employeur assisté :</legend>

                <mat-radio-group aria-labelledby="employeur-assiste" formControlName="employeurAssiste"
                  (change)="employeurAssisteChange(entretien, $event)">
                  <mat-radio-button class="mr-2" [value]="true">Oui</mat-radio-button>
                  <mat-radio-button [value]="false">Non</mat-radio-button>
                </mat-radio-group>
              </fieldset>

              <fieldset *ngIf="isEmployeurAssiste(entretien)" class="info">
                <legend>Information assistant</legend>

                <mat-form-field appearance="outline">
                  <mat-label>Nom et prénom de l'assistant</mat-label>

                  <input type="text" matInput formControlName="nomAssistantEmployeur">
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Qualité</mat-label>

                  <mat-select formControlName="qualiteAssistantEmployeur" [compareWith]="compareFunction">
                    <mat-option *ngFor="let qualite of listeQualiteAssistantEmployeur" [value]="qualite"
                      [title]="qualite.intitule">
                      {{ qualite.intitule }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </fieldset>
            </div>
          </div>
        </div>
      </fieldset>
    </div>

    <button mat-button class="mat-blue" id="add" (click)="ajouterEntretien()" [libDisabled]="entretiens?.value?.length >= 5"
      [hidden]="form.disabled">
      <mat-icon aria-hidden="true">add</mat-icon>
      Ajouter entretien
    </button>

  </section>

  <section id="remarque" class="mt-1">
    <div  class="d-flex align-items-center">
      <h4 class="d-inline-block">Remarques complémentaires</h4>

      <lib-sirc-icon [form]="remarqueEntretiens"
        [tooltipText]="'Les parties ou les personnes qui les assistent, si elles le désirent, peuvent apporter des informations ou commentaires permettant d\'apprécier la liberté de consentement de chacun. Dans ce cas, le nom et la signature de l\'auteur doivent être mentionnés.'">
      </lib-sirc-icon>
    </div>

    <mat-form-field appearance="outline" class="container-fluid">
      <mat-label>Remarques complémentaires</mat-label>

      <textarea matInput formControlName="remarqueEntretiens"></textarea>
    </mat-form-field>
  </section>

  <section id="calendrier">
    <h4>Calendrier de la demande</h4>
    <div class="container-fluid">
      <div class="row">
        <div class="col-5">
          <lib-date-form [form]="form" dateControlName="dateSignature" [isRequired]="true"
            label="Le formulaire est signé par les parties le...">
            <span *ngIf="dateSignature.hasError('signatureIsSameOrAfterInterview')">
              La date de signature ne peut pas être antérieure à celle du premier entretien
            </span>
          </lib-date-form>
        </div>

        <ng-container *ngIf="displayDates; else datesWarning">
          <div class="col-1">
            <mat-divider vertical style="height:100%"></mat-divider>
          </div>

          <div class="col-6">
            <mat-spinner [diameter]="50" *ngIf="showSpinner"></mat-spinner>

            <!-- Calculated dates for TeleRC -->
            <div *ngIf="!isBo; else datesForBo">
              <p>Les parties peuvent <span class="bold">se rétracter</span> pendant 15 jours calendaires, soit jusqu'au
                :</p>
              <p class="date">
                {{ dateFinDelaiRetractation?.value | date:'dd/MM/y' }}
              </p>
              <mat-divider></mat-divider>

              <p>Le formulaire peut être <span class="bold">adressé au service instructeur</span> dès le lendemain, soit
                à compter du :</p>
              <p class="date">
                {{ getDateEnvoiFormulaire() | date:'dd/MM/y'}}
              </p>
              <mat-divider></mat-divider>

              <p>Si vous envoyez le formulaire via le portail <span class="bold">(télétransmission)</span> le :</p>
              <p class="date">
                {{ getDateEnvoiFormulaire() | date:'dd/MM/y'}}
              </p>
              <mat-divider></mat-divider>

              <p>La <span class="bold">rupture du contrat</span> peut intervenir au plus tôt le lendemain de la fin du
                délai d'instruction de 15 jours ouvrables, soit au plus tôt le :</p>
              <p class="date">
                {{getLendemainDateFinDelaiInstruction() | date:'dd/MM/y'}}
              </p>
            </div>

            <!-- Calculated dates for IntraRC -->
            <ng-template #datesForBo>
              <p>Date de fin du délai de rétractation - Contrôle : {{ dateFinDelaiRetractation?.value | date:'dd/MM/y'
                }}</p>
              <p>Date de fin du délai d'instruction : {{ dateFinDelaiInstruction?.value | date:'dd/MM/y' }}</p>
            </ng-template>
          </div>
        </ng-container>

        <ng-template #datesWarning>
          <div class="col-1">
            <mat-divider vertical style="height:100%"></mat-divider>
          </div>
          <div class="col-6 alert alert-warning">
            <p *ngIf="isBo">Veuillez choisir la date de signature et la date de réception
              l'organisme de réception afin de calculer les dates</p>
            <p *ngIf="!isBo">Veuillez saisir la date de signature et la l'adresse de
              l'employeur ou du salarié afin de calculer les dates</p>
          </div>
        </ng-template>

      </div>

      <!-- Send date for IntraRC -->
      <div class="row" *ngIf="isBo">
        <div class="col-5">
          <lib-date-form [form]="parentForm.get('gestion')" dateControlName="dateReception" [isRequired]="true"
            label="Date de réception par l'administration">
          </lib-date-form>
        </div>
      </div>

      <div class="row">
        <div class="col-5">
          <lib-date-form [form]="form" dateControlName="dateEnvisageeRupture" [isRequired]="true"
            label="Date envisagée de la rupture du contrat de travail">
            <span *ngIf="dateEnvisageeRupture.hasError('envisageeRuptureIsAfterInstruction')">
              La date de rupture ne peut intervenir avant le lendemain du jour de l'homologation
            </span>
          </lib-date-form>
        </div>
      </div>

    </div>

  </section>

  <section id="autre">
    <div class="d-flex align-items-center">
      <h4 class="d-inline-block">Autres clauses de la convention de rupture</h4>
      <lib-sirc-icon [form]="autresClauses"
        [tooltipText]="'Cet espace permet d\'apporter des précisions sur les principaux autres droits liés à la rupture du contrat (solde de congés payés ou indemnité compensatrice, droits individuels à formation (DIF), clause de non-concurrence….).'">
      </lib-sirc-icon>
    </div>

    <mat-form-field appearance="outline" class="container-fluid">
      <mat-label>Autres clauses de la convention de rupture</mat-label>
      <textarea matInput formControlName="autresClauses"></textarea>
    </mat-form-field>
  </section>

</form>
