import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: 'toaster-container'
})
export class ToasterDirective implements AfterViewInit {

  constructor(
    private render: Renderer2,
    private elementRef: ElementRef,
  ) {}

  // RGAA : add role in toaster
  ngAfterViewInit(): void {
    this.render.listen(this.elementRef.nativeElement, 'DOMNodeInserted', () => {

      const elementError = this.elementRef.nativeElement.querySelector('.toast-error .toast-content');
      const elementSuccess = this.elementRef.nativeElement.querySelector('.toast-success .toast-content');
      if (elementError) {
        this.render.setAttribute(elementError, 'role', 'alert');
      }
      if (elementSuccess) {
        this.render.setAttribute(elementSuccess, 'role', 'status');
      }
    });
  }

}
