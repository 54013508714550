import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModeOpenDemand } from '../../constants/mode-open-demand.enum';
import { Demande } from '../../models/demande.model';
import { DemandeResolvedData } from '../../models/demandeResolvedData.model';

@Injectable({
  providedIn: 'root'
})
export class DemandeSharedService {

  /**
   * Form group of current demande.
   */
  private _form: FormGroup;

  private _resolvedData: DemandeResolvedData;

  private _demande: Demande;

  private _mode: ModeOpenDemand;

  constructor() { }

  /******* FormGroup accessors *******/

  public get form(): FormGroup {
    return this._form;
  }

  public set form(form: FormGroup) {
    this._form = form;
  }

  public get resolvedData(): DemandeResolvedData {
    return this._resolvedData;
  }

  public set resolvedData(resolvedData: DemandeResolvedData) {
    this._resolvedData = resolvedData;
  }

  public get demande(): Demande {
    return this._demande;
  }

  public set demande(demande: Demande) {
    this._demande = demande;
  }

  public get mode(): ModeOpenDemand {
    return this._mode;
  }

  public set mode(mode: ModeOpenDemand) {
    this._mode = mode;
  }
}
