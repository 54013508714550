import { FormBuilder, FormGroup } from '@angular/forms';
import { CalendrierFormBuilder, DatesValidator, Demande, EmployeurFormBuilder,
  RemunerationFormBuilder, SalarieFormBuilder } from 'sirc-lib';
import { TransmissionFormBuilder } from './transmission-form.builder';

export class DemandeFormBuilder {
  constructor(
    private fb: FormBuilder,
    private demande: Demande,
    private isDisabled: boolean = false,
  ) { }

  build(): FormGroup {

    return this.fb.group({

      id: this.fb.control( this.demande.id),

      uuid: this.fb.control( this.demande.uuid),

      numero: this.fb.control( this.demande.numero),

      courrielContact: this.fb.control(this.demande.courrielContact),

      dateCreation: this.fb.control(this.demande.dateCreation),

      organismeReferent: this.fb.control(this.demande.organismeReferent),

      employeur: new EmployeurFormBuilder(
        this.fb,
        this.demande.employeur,
        this.isDisabled,
      ).build(),

      salarie: new SalarieFormBuilder(
        this.fb,
        this.demande.salarie,
        this.isDisabled,
      ).build(),

      remuneration: new RemunerationFormBuilder(
        this.fb,
        this.demande.remuneration,
        this.isDisabled,
      ).build(),

      calendrier: new CalendrierFormBuilder(
        this.fb,
        this.demande,
        this.isDisabled,
      ).build(),

      // For display indicator in navigation
      validation: this.fb.group({
        dateImpression: this.fb.control(this.demande.dateImpression, DatesValidator.isValidationDone)
      }),

      transmission: new TransmissionFormBuilder(
        this.fb,
        this.demande,
        !!this.demande.dateTeletransmission
      ).build(),

    });
  }
}
