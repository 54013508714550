import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[libDisabled]'
})
export class RgaaDisableDirective implements OnInit, OnDestroy {

  @Input('libDisabled')
  public set disable(value: boolean) {
    this._disable = value;
    this.elementRef.nativeElement.setAttribute('aria-disabled', value);
  }

  private readonly subscription = new Subscription();
  private _disable: boolean;

  constructor(
    private readonly elementRef: ElementRef,
  ) { }

  public ngOnInit(): void {
    this.subscription.add(
      fromEvent(this.elementRef.nativeElement, 'click', { capture: true })
        .subscribe((event) =>  this.onClick(event))
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private onClick(event): boolean {
    // If element is disabled, block click event
    if (this._disable) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      return false;
    }
    return true;
  }
}
